
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div fxLayout="row" id="document-top-bar" *ngIf="isLoggedIn && !isSearchView">
  <div class="document-option">
    <button id="refreshData" mat-button
            [matTooltip]="'REFRESH' | translate"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'document-filter-button-tooltip'"
            (click)="refreshFeed()">
      <mat-icon class="mat-18">refresh</mat-icon>
    </button>
  </div>
  <div class="filter-options">
    <vp-datepicker #datePickerWidget
      [lang]="lang"
      [selected]="selected" 
      [showCustomRangeLabel]="true"
      [alwaysShowCalendars]="true"
      [showClearButton]="true"
      [showCancel]="true"
      [linkedCalendars]="true"
      [keepCalendarOpeningWithRange]="true"
      [showRangeLabelOnInput]="true"
      [placeholder]="'SHOW_ALL' | translate"
      (datesUpdatedEvent)="datesUpdated($event)"
      (resetDateFilterEvent)="resetDateFilter()"
      [matTooltip]="'SELECT_DATE_RANGE' | translate"
      [matTooltipPosition]="'above'"
      [matTooltipClass]="'document-filter-button-tooltip'"
    ></vp-datepicker>

    <div class="user-filter user-smar-filter">
      <div (click)="openSmartFilter()">
        <mat-icon class="mat-18" >group</mat-icon>
        <span class="pl-5">{{selectedUsers}}</span>
        <mat-icon class="mat-18 reset-user-icon" >arrow_drop_down</mat-icon>
      </div>
      <mat-icon class="mat-18 reset-user-icon-filter" (click)="resetUserFilter()" *ngIf="selectedUsers!='All users'">close</mat-icon>
    </div>
    
    <div class="hide_smart_tag">
      <vnc-smart-link-filter-chip  id="user-smart-filter" [searchPlaceholder]="'SEARCH'"  [applyLabel]="'APPLY'" [cancelLabel]="'CANCEL'" [multiple]="true"  type="SELECT" 
      (clearFilter)="applyAllFilters($event)" (apply)="applyAllFilters($event)"  [options]="contacts" 
      [showSearchBox]="true"
      [showClearIcon]="true" 
      [showOperatorOption]="false"></vnc-smart-link-filter-chip> 
    </div>

    <button class="clear-all-button" id="clearData"
            [matTooltip]="'CLEAR_ALL_FILTERS' | translate"
            [matTooltipPosition]="'above'"
            [matTooltipClass]="'document-filter-button-tooltip'"
            mat-button (click)="clearFilters();datePickerWidget.resetDateFilter()">
      <mat-icon class="mat-18">refresh</mat-icon>
      <span>{{ 'CLEAR_ALL' | translate}}</span>
    <!-- <button id="refineFilter" mat-button (click)="setShowFilters(!showFilters)">
      <mat-icon fontSet="mdi" fontIcon="mdi-filter-outline"></mat-icon>
      <span>{{ 'REFINE_FILTER' | translate}}</span>
      <mat-icon *ngIf="!showFilters" fontSet="mdi" fontIcon="mdi-chevron-right"></mat-icon>
      <mat-icon *ngIf="showFilters" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
    </button>
    <button id="clearFilter" mat-button (click)="clearFilters()">
      <mat-icon fontSet="mdi" fontIcon="mdi-refresh"></mat-icon>
      <span>{{ 'CLEAR_ALL_FILTERS' | translate}}</span> -->
    </button>
  </div>
  <div class="right-options">
    <div class="document-option-posts with-dropdown group-by-option"
         [matTooltip]="'SHOW_NUMBER_OF_POSTS' | translate"
         [matTooltipPosition]="'above'"
         [matTooltipClass]="'document-filter-button-tooltip'"
    >
      <mat-icon class="mat-sort-icon" fontSet="mdi" fontIcon="mdi-view-headline"></mat-icon>
      <mat-select panelClass="set-order-by" (selectionChange)="setTotalPosts($event.value)" [(ngModel)]="totalPerPage">

        <ng-container *ngFor="let count of totalPerPageOptions">
          <mat-option class="order-select" [value]="count" >
            <vp-dropdown-item
                    [itemType]="'subheader-icon'"
                    label="{{count}} {{ 'POSTS' | translate }}"
                    sufixImageType="{{totalPerPage == count ? 'check' : null}}"
                    [size]="'m'">
                </vp-dropdown-item>
          </mat-option>
        </ng-container>
      </mat-select>
    </div>
    <!-- <div class="document-option-group with-dropdown group-by-option"
         [matTooltip]="'GROUP_POST' | translate"
         [matTooltipPosition]="'above'"
         [matTooltipClass]="'document-filter-button-tooltip'">
      <mat-icon class="mat-group-icon" fontSet="mdi" fontIcon="mdi-view-module"></mat-icon>

      <mat-select (selectionChange)="setGroupBy($event.value)" [(ngModel)]="groupBy">
        <mat-option class="group-select" value="none" [class.selected]="groupBy === 'none'">{{ 'NONE' | translate}} </mat-option>
        <mat-option class="group-select" value="application" [class.selected]="groupBy === 'application'">{{ 'APPLICATION' | translate}}
        </mat-option>
        <mat-option class="group-select" value="user" [class.selected]="groupBy === 'user'">{{ 'USER' | translate}}
        </mat-option>
      </mat-select>
    </div> -->
    <div class="document-option-sort with-dropdown order-by-option"
         [matTooltip]="'SORT_POST' | translate"
         [matTooltipPosition]="'above'"
         [matTooltipClass]="'document-filter-button-tooltip'">
      <mat-icon class="mat-sort-icon" fontSet="mdi" fontIcon="mdi-sort"></mat-icon>

      <mat-select panelClass="set-order-by" (selectionChange)="setOrderBy($event.value)" [(ngModel)]="orderBy">
        <mat-option class="order-select" value="created_dt desc" [class.selected]="orderBy === 'created_dt desc'">
        
          <vp-dropdown-item
                         [itemType]="'subheader-icon'"
                         label="{{ 'NEWEST_FIRST' | translate}} "
                         sufixImageType="{{orderBy == 'created_dt desc' ? 'check' : null}}"
                         [size]="'m'">
                     </vp-dropdown-item>
         </mat-option>
        <mat-option class="order-select" value="created_dt asc" [class.selected]="orderBy === 'created_dt asc'">
          
          <vp-dropdown-item
          [itemType]="'subheader-icon'"
          label="{{ 'OLDEST_FIRST' | translate}} "
          sufixImageType="{{orderBy == 'created_dt asc' ? 'check' : null}}"
          [size]="'m'">
         </vp-dropdown-item>
         </mat-option>
      </mat-select>
      
    </div>
    <div class="document-option show-unread-option">
      <button [ngClass]="{'is-selected':unreadOnly }"
              [matTooltip]="unreadOnly ? ('SHOW_ALL_POSTS' | translate ) : ('SHOW_ONLY_UNREAD_POSTS' | translate) "
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'document-filter-button-tooltip'"
              (click)="updateUnreadStatus()"
              class="message-kebab-menu" mat-icon-button>
        <mat-icon>check_circle_outline</mat-icon>
      </button>
      <button mat-icon-button
              [matTooltip]="hideAllComments ? ('SHOW_COMMENTS' | translate) : ('HIDE_ALL_COMMENTS' | translate)"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'document-filter-button-tooltip'"
              [ngClass]="{'is-selected':hideAllComments }"
              (click)="toggleHideComments()" class="comment-button">
        <img src='assets/comment.svg' class="commentbutton">
      </button>
      <button id="viewByOption" mat-icon-button
              [matTooltip]="'SELECT_POSTS_VIEW' | translate"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'document-filter-button-tooltip'"
              [matMenuTriggerFor]="appMenu">
        <mat-icon class="current-view" fontSet="mdi" *ngIf="viewBy === 'condense'" fontIcon="mdi-view-headline">
        </mat-icon>
        <mat-icon class="current-view" fontSet="mdi" *ngIf="viewBy === 'detail'" fontIcon="mdi-view-stream"></mat-icon>
      </button>
      <mat-menu #appMenu="matMenu" [class]="'view-by-option-menu'">
        <ng-template matMenuContent>
          <div (click)="setViewBy('condense')" [ngClass]="{'condenseViewActive' : viewBy == 'condense'}">
            <vp-dropdown-item
            [itemType]="'icon-drop-default'"
            label="{{ 'CONDENSED_VIEW' | translate }}"
            sufixImageType="{{viewBy == 'condense' ? 'check' : null}}"
            [size]="'m'" [prefixIconTemplate]="condensedIconTemplate"
            [sufixIconTemplate]="viewBy == 'condense' ? checkedCondenseIconTemplate : null" >
            </vp-dropdown-item>
            <ng-template #condensedIconTemplate>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="18" height="18" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 5v2h17V5M4 11h17V9H4m0 10h17v-2H4m0-2h17v-2H4v2z" fill="#626262"/></svg>
            </ng-template> 
          </div>
          <ng-template #checkedCondenseIconTemplate>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd"/></svg>
          </ng-template> 
           
    
          <div (click)="setViewBy('detail')" [ngClass]="{'detailViewActive' : viewBy == 'detail'}">
              <vp-dropdown-item
              [itemType]="'icon-drop-default'"
              label="{{ 'DETAILED_VIEW' | translate }}"
              sufixImageType="{{viewBy == 'detail' ? 'check' : null}}"
              [size]="'m'" [prefixIconTemplate]="detailIconTemplate"
              [sufixIconTemplate]="viewBy == 'detail' ? checkedDetailIconTemplate : null">
              </vp-dropdown-item>
              <ng-template #detailIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="18" height="18" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M4 5v6h17V5M4 18h17v-6H4v6z" fill="#626262"/></svg>
              </ng-template> 
              <ng-template #checkedDetailIconTemplate>
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="1em" height="1em" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 19.414l-6.707-6.707l1.414-1.414L9 16.586L20.293 5.293l1.414 1.414" fill="#337cbd"/></svg>
              </ng-template> 
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
</div>
<div id="document-list" vpScrollList (onUserScroll)="onUserScroll($event);" *ngIf="isLoggedIn">
  <div class="new-posts-notification" *ngIf="newMessageCount > 0" (click)="refreshFeed()">{{'SEE_NEW_POSTS' | translate }}</div>
  <ng-container *ngIf="groupBy === 'none'">
    <div class="document-item" *ngFor="let document of documents">
      <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
      <vp-condensed-view *ngIf="viewBy !== 'detail'" [document]="document"></vp-condensed-view>
    </div>
  </ng-container>
  <ng-container *ngIf="groupBy === 'application'">
    <section class="docs-by-app" *ngFor="let app of apps">
      <div class="app-header {{app}}-app" (click)="toggleStatus[app] = !toggleStatus[app]">
        <div class="app-name">
            VNC{{ app }}
            <span class="app-results-count" *ngIf="appsList[app]">{{ appsList[app].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[app] = !toggleStatus[app]">
          <mat-icon *ngIf="toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[app]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[app]">
        <div class="document-item" *ngFor="let document of appsList[app]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy !== 'detail'" [document]="document"></vp-condensed-view>
        </div>
      </div>
    </section>
  </ng-container>
  <ng-container *ngIf="groupBy === 'user'">
    <section class="docs-by-app docs-by-user" *ngFor="let user of users">
      <div class="app-header app-header-default" (click)="toggleStatus[user] = !toggleStatus[user]">
        <div class="app-name">
            {{ user | vpGetFullName }}
            <span class="app-results-count" *ngIf="usersList[user]">{{ usersList[user].length }}</span>
        </div>
        <span></span>
        <button mat-icon-button (click)="$event.stopPropagation(); toggleStatus[user] = !toggleStatus[user]">
          <mat-icon *ngIf="toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
          <mat-icon *ngIf="!toggleStatus[user]" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
        </button>
      </div>
      <div class="app-content" *ngIf="!toggleStatus[user]">
        <div class="document-item" *ngFor="let document of usersList[user]">
          <vp-doc-preview *ngIf="viewBy === 'detail'" [document]="document"></vp-doc-preview>
          <vp-condensed-view *ngIf="viewBy !== 'detail'" [document]="document"></vp-condensed-view>
        </div>
      </div>
    </section>
  </ng-container>
  <vnc-no-results *ngIf="totalResults === 0 && !isLoading" description="" message="{{ 'NO_RESULTS' | translate }}"></vnc-no-results>   
</div>

<div class="app-loading" *ngIf="isLoading">
  <vp-custom-spinner></vp-custom-spinner>
</div>
