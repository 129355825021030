/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Inject, Injectable, InjectionToken } from "@angular/core";
import { CalendarDateFormatterInterface, DateFormatterParams } from "./calendar-date-formatter.interface";
import { getWeekViewPeriod } from "./util";
import { DateAdapter } from "../date-adapters/date-adapter";

export const MOMENT: InjectionToken<string> = new InjectionToken("Moment");

/**
 * This will use <a href="http://momentjs.com/" target="_blank">moment</a> to do all date formatting. To use this class:
 *
 * ```typescript
 * import { CalendarDateFormatter, CalendarMomentDateFormatter, MOMENT } from "angular-calendar";
 * import moment from "moment";
 *
 * // in your component
 * provide: [{
 *   provide: MOMENT, useValue: moment
 * }, {
 *   provide: CalendarDateFormatter, useClass: CalendarMomentDateFormatter
 * }]
 *
 * ```
 */
@Injectable()
export class CalendarMomentDateFormatter
  implements CalendarDateFormatterInterface {
  /**
   * @hidden
   */
  constructor(
    @Inject(MOMENT) protected moment: any,
    protected dateAdapter: DateAdapter
  ) {}

  /**
   * The month view header week day labels
   */
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("dddd");
  }

  /**
   * The month view cell day number
   */
  public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("D");
  }

  /**
   * The month view title
   */
  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("MMMM YYYY");
  }

  /**
   * The list view title
  */
  public listViewTitle({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("MMMM YYYY");
  }

  /**
   * The week view header week day labels
   */
  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("dddd");
  }

  /**
   * The week view sub header day and month labels
   */
  public weekViewColumnSubHeader({
    date,
    locale
  }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("MMM D");
  }

  /**
   * The week view title
   */
  public weekViewTitle({
    date,
    locale,
    weekStartsOn,
    excludeDays,
    daysInWeek
  }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn,
      excludeDays,
      daysInWeek
    );
    const format = (dateToFormat: Date, showYear: boolean) =>
      this.moment(dateToFormat)
        .locale(locale)
        .format("MMM D" + (showYear ? ", YYYY" : ""));
    return `${format(
      viewStart,
      viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
    )} - ${format(viewEnd, true)}`;
  }

  /**
   * The time formatting down the left hand side of the week view
   */
  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("ha");
  }

  /**
   * The time formatting down the left hand side of the day view
   */
  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("ha");
  }

  /**
   * The day view title
   */
  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return this.moment(date)
      .locale(locale)
      .format("dddd, D MMMM, YYYY");
  }
}
