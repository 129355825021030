/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { take, takeUntil } from "rxjs/operators";
import { ReplaySubject, Subject } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { AdvSearchFilter } from "../../common/models/adv-search-filter.model";

@Component({
  selector: "vp-multi-filter-with-search",
  templateUrl: "./multi-filter-with-search.component.html"
})
export class MultiFilterWithSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() multiCtrl: FormControl;
  @Input() allValueLabel: string;


  multiFilterCtrl: FormControl = new FormControl("");
  /** list of options filtered by search keyword */
  filteredOptionsMulti: ReplaySubject<AdvSearchFilter[]> = new ReplaySubject<AdvSearchFilter[]>(1);

  previousValue = null;
  @ViewChild("multiSelect", {static: true}) multiSelect: MatSelect;
  private _onDestroy = new Subject<void>();

  private _options: AdvSearchFilter[];

  get options() {
    return this._options;
  }

  @Input() set options(options: AdvSearchFilter[]) {
    this._options = options;
    this.filteredOptionsMulti.next(this.options.slice());
    
  }

  constructor() {
  }

  ngOnInit() {
    // listen for search field value changes
    this.multiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptionsMulti();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  filterOptionsOpened(event: any) {
  
    this.previousValue = this.multiCtrl.value;
  }

  applyFilters() {
    
    this.multiSelect.close();
    return;
  }

  savePreviousFilters() {
    
    this.multiCtrl.setValue(this.previousValue);
    this.multiSelect.close();
  }


  /**
   * Sets the initial value after the filteredoptions are loaded initially
   */
  protected setInitialValue() {
    this.filteredOptionsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredOptions are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.value === b.value;
      });
  }

  protected filterOptionsMulti() {
    if (!this.options) {
      return;
    }
    // get the search keyword
    let search = this.multiFilterCtrl.value;
    if (!search) {
      this.filteredOptionsMulti.next(this.options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the options
    this.filteredOptionsMulti.next(
      this.options.filter(option => option.name.toLowerCase().indexOf(search) > -1)
    );
  }
}
