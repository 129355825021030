
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

setupTheme();

if (environment.production || environment.isCordova || environment.isElectron) {
  enableProdMode();
  // window.console.log = function () {
  // };
  // window.console.debug = function () {
  // };
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function loadExternalStyle(styleUrl: string) {
  return new Promise((resolve, reject) => {
    const styleElement = document.createElement("link");
    styleElement.rel = "stylesheet";
    styleElement.type = "text/css";
    styleElement.href = styleUrl;
    styleElement.onload = () => {
      showApp();
      resolve();
    };
    document.head.appendChild(styleElement);
  });
}

function loadExternalScript(scriptUrl: string) {
  return new Promise(resolve => {
    const scriptElement = document.createElement("script");
    scriptElement.src = scriptUrl;
    scriptElement.onload = resolve;
    document.body.appendChild(scriptElement);
  });
}

function showApp() {
  document.querySelector("vp-app").removeAttribute("style");
}

function setupTheme() {
  const HASH_VALUE = "1db2d2869de94d4420d7d2e4eb4320d1d3aaa46a";
  localStorage.setItem("themeHash", HASH_VALUE);
  if (environment.isCordova) {
    loadExternalScript("cordova.js");
    loadExternalStyle("assets/css/smooth-scroll.css");
  }
  const currentName = localStorage.getItem("theme") || environment.theme;
  const themePath = `assets/theme/${HASH_VALUE}.`;
  document.querySelector("vp-app").setAttribute("style", "display: none");
  loadExternalStyle(themePath + "commander.css").then(() => { showApp(); }).catch(() => { showApp(); });
  document.title = environment.title;
}
