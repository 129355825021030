
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="start-chat-dialog">
    <div class="commander__dialog__header space-between">
        <div class="header_left">
            <button *ngIf="appRepository.isMobileScreen" mat-button (click)="closeDialog()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_title disable-select">
            {{ 'SELECT_ACTIVITY' | translate }}
        </div>
        <div class="header_right close-dialog">
            <a *ngIf="appRepository.isMobileScreen" [href]="appRepository.talkURL" target="_blank" class="open-app open-new-window">
                <div class="vnctalk app-info"><img src="assets/images/vnctalk.svg" />
                </div>
            </a>
            <button *ngIf="!appRepository.isMobileScreen" mat-button (click)="closeDialog()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="commander__dialog__body">
        <ul>
            <li id="startChatBtn" (click)="$event.stopPropagation(); startChat()">
                <button mat-fab>
                    <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-message-reply-text"></mat-icon>
                </button>
                <span class="option-label">{{ 'START_CHAT' | translate }}</span>
            </li>
            <li id="startGroupChatBtn" (click)="$event.stopPropagation(); startGroupChat()">
                <button mat-fab>
                    <mat-icon class="mat-18" fontSet="mdi" fontIcon="mdi-account-multiple"></mat-icon>
                </button>
                <span class="option-label">{{ 'START_GROUP_CHAT' | translate }}</span>

            </li>
            <li id="broadcastbtn" (click)="$event.stopPropagation(); underDevelopment()">
                <button mat-fab class="broadcast-button">
                    <svg style="width:20px;height:32px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.42 62.6">
                        <path fill="#ffffff"
                            d="M72.36,37.44V56.62A4.6,4.6,0,0,1,71,60a5.5,5.5,0,0,1-3.39,1.58,5.15,5.15,0,0,1-1.46-.34,10.62,10.62,0,0,1-3-2.14l-5.87-4.74A31.44,31.44,0,0,0,50,49.63a24.62,24.62,0,0,0-7.45-2.26,40.83,40.83,0,0,0-7.45-.23L41,62.94l.22.91a2.51,2.51,0,0,1-2.48,2.48H29a2.32,2.32,0,0,1-2.25-1.58L20.45,47.14h-.91a5.47,5.47,0,0,1-5-5A9.25,9.25,0,0,1,5.1,32.7a9.59,9.59,0,0,1,2.7-6.89A9,9,0,0,1,14.58,23a5.47,5.47,0,0,1,1.58-3.39,4.59,4.59,0,0,1,3.38-1.35H34q3.84,0,7.9-.34a22.55,22.55,0,0,0,8-2.26A38.19,38.19,0,0,0,57.24,11l5.87-5a17,17,0,0,1,3-2,1.87,1.87,0,0,1,1.46-.23,4.63,4.63,0,0,1,4.74,4.74V27.73a5.5,5.5,0,0,1,3.39,1.58,4.75,4.75,0,0,1-3.39,8.13ZM96.52,32.7A22.37,22.37,0,0,1,94.6,42a27.81,27.81,0,0,1-5.08,7.68l-6.77-6.78a13.9,13.9,0,0,0,4.06-10.27,13.93,13.93,0,0,0-4.06-10.27l6.77-6.77a27.81,27.81,0,0,1,5.08,7.68A22.91,22.91,0,0,1,96.52,32.7Z"
                            transform="translate(-5.1 -3.73)" /></svg>
                </button>
                <span class="option-label">{{ 'BROADCAST' | translate }}</span>
            </li>
            <li id="shareScreen" (click)="$event.stopPropagation(); underDevelopment()">
                <button mat-fab class="screenshare-button">
                    <img src='assets/screen_share.svg' class="sharescreenbutton">
                </button>
                <span class="option-label">{{ 'START_SCREENSHARE_SESSION' | translate }}</span>

            </li>
            <li id="inviteExternalUserBtn" (click)="$event.stopPropagation(); underDevelopment()">
                <button mat-fab class="invite-button">
                    <img src='assets/external-user.svg' class="external-user-img">
                </button>
                <span class="option-label">{{ 'INVITE_EXTERNAL_USER' | translate }}</span>
            </li>
        </ul>
    </div>
    <div class="commander__dialog__footer align-start">
        <a [href]="appRepository.talkURL" target="_blank" class="open-app">
            <div class="vnctalk app-info"><img src="assets/images/vnctalk.svg" />
            </div>
            {{ 'OPEN_IN' | translate : { appName: 'VNCtalk'} }}
        </a>
    </div>
</div>