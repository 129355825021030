
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vnc-date-picker" [ngClass]="{'de': lang === 'de'}">
  <label for="calendar-input">
    <mat-icon class="event-icon">event</mat-icon>
  </label>
  <input type="text"
         id="calendar-input"
         readonly
         ngxDaterangepickerMd
         #picker
         [(ngModel)]="selected"
         [showCustomRangeLabel]="showCustomRangeLabel"
         [alwaysShowCalendars]="alwaysShowCalendars"
         [ranges]="ranges"
         [showClearButton]="showClearButton"
         [showCancel]="showCancel"
         [linkedCalendars]="linkedCalendars"
         [isTooltipDate] = "isTooltipDate"
         [isInvalidDate] = "isInvalidDate"
         [locale]="locale"
         (datesUpdated)="datesUpdated($event)"
         [keepCalendarOpeningWithRange]="keepCalendarOpeningWithRange"
         [showRangeLabelOnInput]="showRangeLabelOnInput"
         [placeholder]="placeholder"/>
  <mat-icon class="reset-date-icon" (click)="resetDateFilter()" *ngIf="!isDefaultDate">close</mat-icon>
</div>
