/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { getAllContacts, getContactById, getNetworkInformation, getUserProfile, RootState } from "../reducers";
import { Store } from "@ngrx/store";
import { ConfigService } from "../config.service";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from "@angular/common/http";
import { fromEvent, merge, Observable, of, Subject, throwError } from "rxjs";
import { SearchItem, SearchResponse } from "../common/models/mail-models/search-item";
import { catchError, map, mapTo, take, tap } from "rxjs/operators";
import { CommonUtils } from "../common/utils/common-util";
import { OnlineStatus, SetCountForApps, SetUnreadCountForApps, UpdateNetworkInformation } from "../actions/app";
import { CommanderConstants } from "../common/utils/commander.constants";
import * as moment from "moment";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Message } from "../common/models/message.model";
import { AuthService } from "src/app/common/providers/auth.service";
import { SaveSendMessage } from "src/app/common/models/mail-models/save-send.model";
import { SearchRequest } from "src/app/common/models/mail-models/search-request.model";
import { TaskUser } from "../common/models/task-models/task-user";
import { ContactInfo } from "src/app/common/models/contact-info.model";
import { FeedRequest } from "../common/models/feed-request.model";
import { User } from "@sentry/browser";

const VNCTALK_API = CommanderConstants.VNCTALK_API;
const VNCMAIL_API = CommanderConstants.VNCMAIL_API;
const VNCTASK_API = CommanderConstants.VNCTASK_API;

@Injectable()
export class AppService {
    private isCordovaOrElectron = environment.isCordova || environment.isElectron;
    isNetworkOnline: any;
    loggedInUser: User;
    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private domSanitizer: DomSanitizer,
        private auth: AuthService,
        private store: Store<RootState>) {
            this.store.select(getNetworkInformation).subscribe(lastState => {
                if (!lastState) {
                  return;
                }

                const isConnected = lastState.onlineState;
               
                // if network changed
                if (this.isNetworkOnline !== isConnected) {
                  this.isNetworkOnline = isConnected;
                  this.store.dispatch(new OnlineStatus(isConnected));
                }

                // edge case
                if (!isConnected && navigator.onLine) {
                  this.store.dispatch(new UpdateNetworkInformation({
                    onlineState: navigator.onLine,
                    connectionType: (lastState && lastState.connectionType) || "unknown"
                  }));
                }
            });

            // network changes
            merge(
                fromEvent(window, "online").pipe(mapTo(true)),
                fromEvent(window, "offline").pipe(mapTo(false))
            ).subscribe(this.notifyConnectionStatus.bind(this));
    }

    private notifyConnectionStatus(isConnected: boolean) {
        this.store.dispatch(new OnlineStatus(isConnected));

        let connectionType = "unknown";
        if (navigator.connection) {
            connectionType = navigator.connection.type ? navigator.connection.type : navigator.connection.effectiveType || "unknown";
        }
        
        //
        this.store.dispatch(new UpdateNetworkInformation({
            onlineState: isConnected,
            connectionType: connectionType
        }));
    }

    public getConfig(serverUrl: string): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        return this.http.get(serverUrl + "/api/config", { headers: headers });
    }

    private getAPI(): string {
        return this.configService.API_URL;
    }

    public searchDocs(body: FeedRequest): Observable<SearchResponse> {
        let myContacts = [];
        let loggedInUser = null;
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        return this.http.post(this.getAPI() + "/api/search", body, { headers: headers }).pipe(
          tap(
            (res: any) => {
              this.http.get(this.getAPI() + VNCTALK_API + "/getTotalUnreadCount", { headers: headers}).subscribe(
                (data: any) => {
                  let unreadCount = {
                    talk: +data.unread,
                  };
                  if (res.facet_counts && res.facet_counts.facet_queries) {
                    const queries = res.facet_counts.facet_queries;
                    unreadCount["mail"] = +queries["(mail_unread_b:true AND mail_folder_id_i:2)"];
                    unreadCount["task"] = +queries["((type_s:issue AND issue_type:task) AND -status_s:Completed)"];
                    this.store.dispatch(new SetUnreadCountForApps(unreadCount));
                  }
                  
                }
              );
            }
          ),
          tap(
            (res: any) => {
              this.store.select(getAllContacts).subscribe( contacts => {
                myContacts = contacts.map( v => ({email: v.defaultMail, name: v.name}));
              });
              this.store.select(getUserProfile).subscribe(user => {
                this.loggedInUser = user;
                loggedInUser = user;
              });
            }
          ),
          map((res: any) => {
            let docs = [];
            let numFound = 0;
            let start = 0;
            let groups = {};
            if (res.response) {
                docs = res.response.docs.map(val => {
                    return this.mapSearchItem(val);
                });
                numFound = res.response.numFound;
                start = res.response.start;
            } else if (!!res.grouped || !!res.ungrouped) {
              if (res.grouped && res.grouped.talk_jid_s && res.grouped.talk_jid_s.groups) {
                const talkDocs = [];
                res.grouped.talk_jid_s.groups.forEach(group => {
                  if (group.doclist.start) {
                    start = group.doclist.start;
                  }
                  if (!!group.groupValue && group.doclist.docs.length > 0 && group.groupValue !== loggedInUser.defaultMail) {
                    const doc = this.mapTalkGroupIntoSearchItem(group, myContacts);
                    talkDocs.push(doc);
                  }
                });
                docs = [...talkDocs];
                numFound = res.grouped.talk_jid_s.matches;
              }

              if (res.ungrouped && res.ungrouped.docs) {
                const nonTalkDocs = res.ungrouped.docs.map(val => {
                  return this.mapSearchItem(val);
                });
                const nonTalkNumFound = res.ungrouped.numFound;
                docs = [...docs, ...nonTalkDocs];
                numFound = numFound + nonTalkNumFound;
              }

              if (body.sort &&  body.sort.includes("created_dt asc")) {
                docs.sort((a, b) => (a.createdDt > b.createdDt) ? 1 : -1);
              } else {
                docs.sort((a, b) => (a.createdDt < b.createdDt) ? 1 : -1);
              }
            } else if (res.grouped && res.grouped.type_s && res.grouped.type_s.groups) {
                numFound = res.grouped.type_s.matches;
                res.grouped.type_s.groups.forEach(group => {
                    if (group.doclist.start) {
                        start = group.doclist.start;
                    }
                    const _docs = group.doclist.docs.map(val => {
                        return this.mapSearchItem(val);
                    });
                    groups[group.groupValue] = _docs;
                    docs = [...docs, ..._docs];
                });
            } else if (res.grouped && res.grouped.from_s && res.grouped.from_s.groups) {
                numFound = res.grouped.from_s.matches;
                res.grouped.from_s.groups.forEach(group => {
                    if (group.doclist.start) {
                        start = group.doclist.start;
                    }
                    const _docs = group.doclist.docs.map(val => {
                        return this.mapSearchItem(val);
                    });
                    groups[group.groupValue] = _docs;
                    docs = [...docs, ..._docs];
                });
            }
            if (res.facet_counts && res.facet_counts.facet_fields && res.facet_counts.facet_fields.type_s) {
                const apps = {};
                const type = res.facet_counts.facet_fields.type_s;
                for (let i = 0; i <= type.length; i++) {
                    if (typeof type[i] === "string" && i < type.length - 1) {
                        apps[type[i]] = type[i + 1];
                    }
                }
               
                this.store.dispatch(new SetCountForApps(apps));
            } else {
                this.store.dispatch(new SetCountForApps({}));
            }
            return { docs: docs, numFound: numFound, start: start, groups: groups } as SearchResponse;
        }));
    }


    public uploadAvatar(files): Observable<any> {
        let headers = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        return this.http.post(this.getAPI() + "/api/upload_avatar", files,
            {
                headers: headers,
                responseType: "text"
            });
    }

    public getMyProducts(): Observable<any> {
        let headers = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        if (localStorage.getItem("products")) {
          let products;
          try {
            products = JSON.parse(localStorage.getItem("products"));
            return of({products});
         } catch (e) {
            console.error(e);
            return of({products: []});
         }
        }
        return this.http.get(this.getAPI() + "/api/my-products", {
            headers: headers
        });
    }

    public getLoggedInUserInfo(): Observable<any> {
        let headers = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        return this.http.get(this.getAPI() + "/api/getLoggedInUserInfo", { headers: headers }).pipe(map((res: any) => {
            if (!!res && res !== null && res.contact) {
              return this.getMapContacts(res.contact);
            } else {
              let email = "abc@sdasd.sd";
              const contact = new ContactInfo();
              contact.jid = email;
              contact.fullName = email.substring(0, email.indexOf("@"));
              contact.phones = [];
              contact.emails = [];
              contact.urls = [];
              return contact;
            }
        }));
    }

    public getMyProjects(): Observable<any> {
      if (localStorage.getItem("projects")) {
        let projects;
          try {
            projects = JSON.parse(localStorage.getItem("projects"));
            return of({projects});
         } catch (e) {
            console.error(e);
            return of({projects: []});
         }
      }
        return this.get("/api/my-projects", true);
    }

    public getMyProjectDetails(projectId: number): Observable<any> {
      return this.get("/api/my-projects/" + projectId, true);
    }

    public getPriorities(): Observable<any> {
      if (localStorage.getItem("issue_priority")) {
        let issue_priority;
          try {
            issue_priority = JSON.parse(localStorage.getItem("issue_priority"));
            if (new Date() > new Date(issue_priority.expiry)) {
              return this.get("/api/issue-priorities", true);
            } else {
              return of(issue_priority);
            }
         } catch (e) {
            console.error(e);
            return of({});
         }
      }
        return this.get("/api/issue-priorities", true);
    }

    public getStatuses(): Observable<any> {
      if (localStorage.getItem("issue_statuses")) {
        let issue_statuses;
        try {
          issue_statuses = JSON.parse(localStorage.getItem("issue_statuses"));
          return of({issue_statuses});
       } catch (e) {
          console.error(e);
          return of({issue_statuses: []});
       }
       
      }
        return this.get("/api/issue-statuses", true);
    }

    public getUploadUrl(fileName, fileSize): Observable<any> {
        let headers = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        return this.http.get(this.getAPI() + "/api/get-upload-url",
            {
                headers: headers,
                params: {filename: fileName, fileSize: fileSize},
                responseType: "text"
            });
    }

    public searchLDAP(keyword): Observable<any> {
        let headers = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        if (localStorage.getItem("search-ldap")) {
          let ldpData;
          try {
            ldpData = JSON.parse(localStorage.getItem("search-ldap"));
            return of(ldpData);
         } catch (e) {
            console.error(e);
            return of([]);
         }
        }
        return this.http.post<any[]>(this.getAPI() + VNCTALK_API + "/search-ldap", {
            keyword: keyword
        }).pipe(map(res => {
            if (res === null) {
              localStorage.setItem("search-ldap", JSON.stringify([]));
                return [];
            }
            let ldapData = res.map(user => {
                  return {
                      name: CommonUtils.getDisplayNameFromLDAPEntry(user),
                      email: user.mail[0],
                      ldapData: user
                  };
              });
              localStorage.setItem("search-ldap", JSON.stringify(ldapData));
            return ldapData;
        }));
    }

    getConvHistory(offset: number, limit: number = 100, updated_after?: number): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: any = {
            headers: headers,
            params: {
                "resultCount": limit,
                "offset": offset
            }
        };
        if (!!updated_after) {
            options.params.updated_after = Math.floor(updated_after / 1000);
            localStorage.setItem("lastPollingTimeStamp", String(new Date().getTime()));
        }
        return this.http.get(this.getAPI() + VNCTALK_API + "/convHistory", options);
    }

    private buildHeaders(useAuthHeaders: boolean, headers?: HttpHeaders): HttpHeaders {
        if (!useAuthHeaders) {
            return headers;
        }
        let mutatedHeaders: HttpHeaders;
        if (!!headers) {
            mutatedHeaders = headers;
        } else {
            mutatedHeaders = new HttpHeaders();
        }
        const token = localStorage.getItem("token");
        if (token && this.isCordovaOrElectron) {
            if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
                return mutatedHeaders.set("Authorization", token).set("Content-Type", "text/plain").set("Accept", "text/plain");
            }
            return mutatedHeaders.set("Authorization", token).set("Content-Type", "application/json").set("Accept", "application/json");
        } else {
            if (mutatedHeaders.has("Content-Type") && mutatedHeaders.get("Content-Type") === "text/plain") {
                return mutatedHeaders.set("Content-Type", "text/plain").set("Accept", "text/plain");
            }
            return mutatedHeaders.set("Content-Type", "application/json").set("Accept", "application/json");
        }
    }

    private buildParams(data: any): HttpParams {
        let params = new HttpParams();
        if (data) {
          for (const key of Object.keys(data)) {
            params = params.append(key, data[key]);
          }
        }
        return params;
    }

    get<T>(url: string, useAuthHeaders: boolean, data?: any, headers?: HttpHeaders,
        useBaseUrl: boolean = true, responseType?: string): Observable<T> {
          const options = {
          params: this.buildParams(data),
          headers: this.buildHeaders(useAuthHeaders, headers)
        };
        if (responseType) {
          options["responseType"] = responseType;
        }
        let baseUrl = this.getAPI() + url;
        if (!useBaseUrl) {
          baseUrl = url;
        }
        return this.http.get<T>(baseUrl, options);
    }

    post<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<T> {
        
        return this.http.post<T>(this.getAPI() + url, data, {
          headers: this.buildHeaders(useAuthHeaders, headers),
          withCredentials: true
        });
    }

    put<T>(url: string, useAuthHeaders: boolean, data: any, headers?: HttpHeaders): Observable<T> {
        
        return this.http.put<T>(this.getAPI() + url, data, {
          headers: this.buildHeaders(useAuthHeaders, headers),
          withCredentials: true
        });
    }

    delete<T>(url: string, useAuthHeaders: boolean, headers?: HttpHeaders): Observable<T> {
      
      return this.http.delete<T>(this.getAPI() + url, {
        headers: this.buildHeaders(useAuthHeaders, headers),
        withCredentials: true
      });
    }

    getComments(params?: any): Observable<any> {
        const url = "/api/meta_comments";
        return this.get(url, true, params);
    }

    addComment(param: any, text: string, parentId?: number) {
        let body = {
            ...param,
            comment: {
                comments: text,
                parent_comment_id: parentId || ""
            }
        };
        
        return this.post(`/api/meta_comments`, true, body);
    }

    getTags(params?: any): Observable<any> {
        const url = "/api/tags";
        return this.get(url, true, params);
    }

    getFilteredVNCDTags(params: {name: string}): Observable<any> {
      const url = "/api/tags/getTags";
      return this.get(url, true, params);
    }

    createNewVNCDTag(tagName: string, tagColor: string): Observable<any> {
      const data = {
        tag: {
          name: tagName,
          configs: {
            color_hex: tagColor
          }
        }
      };
      return this.post(`/api/tags/createTag`, true, data);
    }

    updateTagsInPost(postId: string, tags: string[]) {
      const data = {
        id: postId,
        tags: tags,
      };
      return this.post(`/api/addTags`, true, data);
    }

    getTagsByJid(jid): Observable<any> {
        let url = "/api/taggings";
        const params = {
          product: "vnctalk",
          object_type: "meeting",
          object_ids: jid
        };
        const query = Object.keys(params).map(key => {
          return `${key}=${params[key]}`;
        });
        url += "?" + query.join("&");
        
        return this.get(url, true);
    }

    createOrUpdateTag(jid, tags) {
        const objects = {"0": {id: jid, tags_list: tags.join(",")}};
        const body = {
          taggings: {
            product: "vnctalk",
            object_type: "meeting",
            objects: objects
          }
        };
        return this.post(`/api/taggings`, true, body);
    }

    createGroup(name: string, contact_ids?: number[]) {
        const data = {
            contact_group: {
              name: name,
              contact_ids: contact_ids || []
            }
        };
        return this.post(VNCTALK_API + `/contact_groups.json`, true, data);
    }

    getContacts(queryParams: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let data: any = { headers: headers };
        if (queryParams) {
            data.params = queryParams;
        }
        return this.http.get(this.getAPI() + VNCTALK_API + "/contacts.json", data);
    }

    getGroups(queryParams: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let data: any = { headers: headers };
        if (queryParams) {
            data.params = queryParams;
        }
        return this.http.get(this.getAPI() + VNCTALK_API + `/contact_groups.json`, data);
    }

    syncMessages(queryParams: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        let options: any = { headers: headers };
        return this.http.post(this.getAPI() + VNCTALK_API + `/sync-messages`, queryParams, options);
    }

    getAroundMessages(queryParams: any): Observable<any> {
        return this.post(VNCTALK_API + `/around-msgid`, true, queryParams);
    }

    sendMessage(data: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        let options: any = { headers: headers };
        if (data.data) {
            return of(data.data.id);
        }
        return this.http.post(this.getAPI() + VNCTALK_API + `/xmpp-rest`, data, options);
    }

    sendMail(data: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        let options: any = { headers: headers };
        return this.http.post(this.getAPI() + VNCTALK_API + `/sendmail`, data, options);
    }

    messageAction(body: any): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        const t1 = new Date().getTime();
        const params = JSON.stringify(body);
        return this.http.post(this.getAPI() + VNCMAIL_API + "/msgAction", body,
            options).pipe(map(this.returnResponse), tap(res => {
                const t2 = new Date().getTime();
                const performance = (t2 - t1) / 1000;
                
                CommonUtils.sentryLog(`[API][messageAction] took ${performance} s - ${params}`, performance);
            }), catchError(this.auth.handleErrorObservable.bind(this)));
    }

    getAllRoomMembers(): Observable<any> {
        return this.http.get(this.getAPI() + VNCTALK_API + "/roomMembers/all");
    }

    getRoomMembers(target): Observable<any> {
        return this.http.get(this.getAPI() + VNCTALK_API + "/roomMembers/" + target);
    }

    getAudienceList(broadcastId: string): Observable<any> {
        return this.http.get(this.getAPI() + VNCTALK_API +  "/broadcastAudience/" + encodeURIComponent(broadcastId));
    }

    loadMessages(target: string, maxItems: number, offset: number = 0,
        startTimestamp?: number, endTimestamp?: number): Observable<{messages: Message[], total: number}> {
        let data = {};
        data["rows"] = maxItems;
        data["offset"] = 0;
        data["tags"] = [];
        if (target.indexOf("@conference") === -1) {
            data["excludeGroupchat"] = true;
            data["peerMatch"] = target;
        } else {
            data["excludeGroupchat"] = false;
            data["confId"] = target;
            data["roomMatch"] = target;
        }
        if (startTimestamp) {
            data["nb"] = moment(new Date(startTimestamp)).utc().format();
        }
        if (endTimestamp) {
            data["na"] = moment(new Date(endTimestamp)).utc().format();
        }
        if (offset) {
            data["offset"] = offset;
        }
        const response = new Subject<any>();
        this.syncMessages(data).subscribe(res => {
            let messages: Message[] = res.docs.map(message => CommonUtils.convertToXmppMessage(message));
            const deletedMessages = messages.filter(msg => msg.replace).map(msg => msg.replace.id);
            messages = messages.filter(msg => !msg.replace).map(msg => {
                msg.isDeleted = false;
                if (deletedMessages.indexOf(msg.id) !== -1) {
                    msg.isDeleted = true;
                    msg.body = null;
                }
                return msg;
            });
            response.next({ messages: messages, total: res.numFound });
        });
        return response.asObservable().pipe(take(1));
    }

    getContact(contactId: number): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        return this.http.get<any>(this.getAPI() + VNCTALK_API + `/contacts/${contactId}`, { headers: headers });
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error("An error occurred:", error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            "Something bad happened; please try again later.");
    }


    private mapSearchItem(doc) {
        const content = doc.content_txt ? doc.content_txt[0] : "";
        const raw = doc.raw_txt ? doc.raw_txt[0] : "";
        let parsedContent = CommonUtils.replaceLinkToAnchor(content);
        const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
        if (rawTxt !== "") {
            parsedContent = CommonUtils.beautifyMailContent(rawTxt);
        }
        const shortContent = CommonUtils.replaceLinkToAnchor(CommonUtils.limitContent(CommonUtils.HTMLToPlainText(parsedContent), 500));
        let id = doc.id;
        let objectId = doc.id;
        if (doc.type_s === "issue") {
            id = doc.issue_id_i;
        } else if (doc.type_s === "mail") {
            id = doc.mail_id_i;
            objectId = objectId.replace(`.${doc.mail_id_i}`, "");
        } else if (doc.type_s === "talk") {
            objectId = objectId.replace(`.${doc.owner_s}`, "").replace(`talk.${doc.talk_id_s}.`, "");
            objectId = objectId.replace(`talk.`, "");
        }
        return {
            id: id,
            objectId: objectId,
            talkId: doc.talk_id_s,
            owner: doc.owner_s,
            title: doc.title_s ? doc.title_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "",
            contentTxt: content.replace(/\r?\n/g, "\\n")
            .replace(/\\n/g, "<br />"),
            parsedContent: parsedContent.replace(/\r?\n/g, "\\n")
            .replace(/\\n/g, "<br />"),
            shortContent: shortContent.replace(/\r?\n/g, "\\n")
            .replace(/\\n/g, "<br />"),
            chatType: doc.talk_type_s,
            rawTxt: raw,
            createdDt: doc.created_dt,
            modifiedDt: doc.modified_dt,
            from: doc.from_s,
            to: doc.to_ss,
            type: doc.type_s,
            version: doc._version_,
            mailFolderID: doc.mail_folder_id_i,
            unread: doc.mail_unread_b,
            mailAttachments: this.getMailAttachments(doc),
            flags: doc.mail_flags_s,
            tags: doc.tags_ss,
            solrId: doc.id,
        } as SearchItem;
    }

    mapTalkGroupIntoSearchItem(group, contacts: {email: string, name: string}[]) {
     
      const doc = group.doclist.docs[0];
      let groupedContent = "";
      let chatType = "chat";
      let title = group.groupValue;
      if (group.groupValue.indexOf("@conference.") !== -1) {
        chatType = "groupchat";
      } else if (group.groupValue.indexOf("broadcast") !== -1) {
        chatType = "broadcast";
      }
      let sender = doc.from_s;
      if (chatType === "chat") {
        sender = group.groupValue.split("-")[1];
        let user = title.split("-")[1];
        if (this.loggedInUser.email ===  user) {
            user =  title.split("-")[0];
          }
        let userList = JSON.parse(localStorage.getItem("saved_state"));
        if (userList) {
            user = userList.contactState.entities[user];
            if (user) {
              title = user.name;
            }
        }
        user = userList.contactState[user];
      }

      group.doclist.docs.map( d => {
        let senderName = "";
        const sender = contacts.find(c => c.email === d.from_s);
        let userSpan = `<span class="user-name non-capital">${d.from_s}</span>`;
        if (!!sender) {
          senderName = sender.name;
          userSpan = `<span class="user-name">${senderName}</span>`;
        }



        const content = d.content_txt ?
          `<div class="one-message">` + userSpan + `: ${d.content_txt[0]} </div> ` : "";
        if (!!content) {
          groupedContent = groupedContent + content + "<br>";
        }
      });

      // const content = doc.content_txt ? doc.content_txt[0] : "";
      const raw = doc.raw_txt ? doc.raw_txt[0] : "";
      // TODO change raw txt to be combo of 5 text messages
      let parsedContent = CommonUtils.replaceLinkToAnchor(groupedContent);
      // const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
      // if (rawTxt !== "") {
      //   parsedContent = CommonUtils.beautifyMailContent(rawTxt);
      // }
      const shortContent = CommonUtils.replaceLinkToAnchor(CommonUtils.limitContent(CommonUtils.HTMLToPlainText(parsedContent), 500));
      let id = doc.id;
      let objectId = doc.id;
      objectId = objectId.replace(`.${doc.owner_s}`, "").replace(`talk.${doc.talk_id_s}.`, "");
      objectId = objectId.replace(`talk.`, "");

      return {
        id: id,
        objectId: objectId,
        talkId: doc.talk_id_s,
        owner: doc.owner_s,
        title,
        contentTxt: groupedContent.replace(/\r?\n/g, "\\n")
          .replace(/\\n/g, "<br />"),
        parsedContent: parsedContent.replace(/\r?\n/g, "\\n")
          .replace(/\\n/g, "<br />"),
        shortContent: shortContent.replace(/\r?\n/g, "\\n")
          .replace(/\\n/g, "<br />"),
        chatType: chatType,
        rawTxt: raw,
        createdDt: doc.created_dt,
        modifiedDt: doc.updated_dt,
        from: sender,
        to: doc.to_ss,
        type: doc.type_s,
        version: doc._version_
      } as SearchItem;
    }

    private getMailAttachments(doc) {
        if (!doc.mail_attachments_s) {
            return [];
        }
        const attachments = JSON.parse(doc.mail_attachments_s);
        return attachments.map(v => {
            return {
                id: v.id.replace(doc.mail_message_id_s, "").replace("_", ""),
                name: v.name_s,
                type: v.type_s,
                contentType: v.contenttype_s,
                contentLength: v.contentlength_i,
                parentId: v.parent_id_s,
                part: v.part_s
            };
        });
    }

    public renderEmoji(input: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(wdtEmojiBundle.render(input));
    }

    private returnResponse(res: Response): Response {
        return res;
    }

    uploadFile(url, file): Observable<HttpEvent<any>> {
        const headers = new HttpHeaders().set("Content-Type", "application/octet-stream");
        const req = new HttpRequest("PUT", url, file, {
          headers: headers,
          reportProgress: true,
        });
        return this.http.request(req);
    }

    getMailFolders(view = "message, conversation"): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        return this.http.post(this.getAPI() + VNCMAIL_API + "/getFolderList", {view: view}, options);
    }

    searchRequest(body: SearchRequest): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        return this.http.post(this.getAPI() + VNCMAIL_API + "/searchRequest", body, options);
    }

    getAutoCompleteList(inputValue: string): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        const body = { name: inputValue };
        return this.http.post(this.getAPI() + VNCMAIL_API + "/autocomplete",
        {name: inputValue}, options)
        .pipe(map(this.returnResponse), catchError(this.auth.handleErrorObservable.bind(this)));
    }

    saveMailAsDraft(saveSendMessage: SaveSendMessage): Observable<any> {
        const t1 = new Date().getTime();
        const params = JSON.stringify(saveSendMessage);
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        return this.http.post(this.getAPI() + VNCMAIL_API + "/saveDraft",
            saveSendMessage, options).pipe(map(this.returnResponse), tap(res => {
              const t2 = new Date().getTime();
              const performance = (t2 - t1) / 1000;
              
              CommonUtils.sentryLog(`[API][saveDraft] took ${performance} s - ${params}`, performance);
            }),
            catchError(this.auth.handleErrorObservable.bind(this)));
      }

    sendEmail(saveSendMessage: SaveSendMessage): Observable<any> {
        const t1 = new Date().getTime();
        const params = JSON.stringify(saveSendMessage);
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        return this.http.post(this.getAPI() + VNCMAIL_API + "/sendEmail",
            saveSendMessage, options).pipe(tap(res => {
              const t2 = new Date().getTime();
              const performance = (t2 - t1) / 1000;
              
              CommonUtils.sentryLog(`[API][sendEmail] took ${performance} s - ${params}`, performance);
            }),
            map(this.returnResponse), catchError(this.auth.handleErrorObservable.bind(this)));
      }

      uploadAttachment(file: any): Observable<any> {
        const formData = new FormData();
       
        formData.append("file", file);
        const t1 = new Date().getTime();
        let headers: HttpHeaders = new HttpHeaders();
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Authorization": token });
        }
        return this.http.post(this.getAPI() + "/api/vncmail_upload", formData,
        {
            headers: headers,
            responseType: "text"
        }).pipe(tap(res => {
              
              const t2 = new Date().getTime();
              const performance = (t2 - t1) / 1000;
             
              CommonUtils.sentryLog(`[API][uploadAttachment] took ${performance} s for API call`, performance);
            }));
      }

      updateGroupAvatar(target, base64Data: string) {
        const data = { "avatar_base64": "data:image/png;base64," + base64Data };
        let headers = new HttpHeaders({ "Content-Type": "text/plain;charset=UTF-8" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "text/plain;charset=UTF-8", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        return this.http.post(this.getAPI() + "/api/group_chats/avatar/" + target, data, options);
      }

      updateGroupInfo(target, params) {
        const data = { "group_chat": params};
        return this.post(`/api/group_chats/${target}`, true, data);
      }

      getMsgRequest(id: string): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };

        const t1 = new Date().getTime();
        return this.http.post(this.getAPI() + VNCMAIL_API + "/getMsgRequest", { id }, options)
        .pipe(tap(res => {
            
            const t2 = new Date().getTime();
            const performance = (t2 - t1) / 1000;
           
            CommonUtils.sentryLog(`[API][getMsgRequest] took ${performance} s - ID=${id}`, performance);
        }));
    }

    getAttributes (section: string): Observable<any> {
        let headers = new HttpHeaders({ "Content-Type": "application/json" });
        if (this.isCordovaOrElectron) {
            const token = localStorage.getItem("token");
            headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
        }
        let options: object = {
            headers: headers
        };
        const t1 = new Date().getTime();
        return this.http.get(this.getAPI() + VNCMAIL_API + "/getInfo?sections=" + section , options)
        .pipe(tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
         
          CommonUtils.sentryLog(`[API][getInfo]sections=${section} took ${performance} s for API call`, performance);
        }));
    }

    getProjectMembers(projectId: number): Observable<TaskUser[]> {
      return this.get(VNCTASK_API + "/task/projects/" + projectId + "/memberships.json?" + "only_user_details=1&limit=1000", true)
        .pipe(map((res: any) => res.users.map(json => new TaskUser(json))));
    }

    changeTaskCompletedStatus(taskId: string, completed: boolean) {
      let redmineStatus = {new: 1, inProgress: 2, completed: 18};
      let payload: any  = {
        issue: {
          status_id: completed ? redmineStatus.completed : redmineStatus.inProgress
        }
      };
      let headers = new HttpHeaders({ "Content-Type": "application/json" });
      return this.put(VNCTASK_API + "/task/issues/" + taskId + ".json?include=attachments,tags", true,
        payload, headers
      );
    }

    addNewTask(payload: any): Observable<any> {
      return this.post(VNCTASK_API + "/task/issues.json?include=tags,list,invitation_details", true, {issue: payload});
    }

    getTaskDetails(id: number): Observable<any> {
      return this.get<string>(VNCTASK_API + "/task/issues/" + id + ".json?include=journals,attachments,tags", true);
    }

    editTask(taskId: number, payload: any): Observable<any> {
      return this.put(VNCTASK_API + "/task/issues/" + taskId + ".json?include=tags,list,invitation_details", true, {issue: payload});
    }

    deleteTask(taskId: number) {
      const selectedIds = [taskId];
      return this.delete(VNCTASK_API + "/task/vnctasks/delete.json?ids[]=" + selectedIds.join("&ids[]="), true);
    }

    getSavedSearches(): Observable<any> {
      return this.get<any>("/api/savedSearch/get", true).pipe(map(res => {
        if (res === null) {
          return [];
        }
        return res.response.docs.map( doc => {
          const name = doc.name_s;
          const searchTerm = doc.search_term_txt[0];
          const filters = JSON.parse(doc.filters_txt[0]);
          return {id: doc.id, name, searchTerm, filters};
        });
      }));
    }

    addSearch(body: any): Observable<any> {
      return this.post(`/api/savedSearch/create`, true, body);
    }

    updatedSearch(body: any): Observable<any> {
      return this.put(`/api/savedSearch/update`, true, body);
    }

    deleteSearch(searchId: any): Observable<any> {
      let body = {
        id: searchId
      };
      return this.put(`/api/savedSearch/delete`, true, body);
    }

    addNewTicket(payload: any): Observable<any> {
      return this.post("/api/tickets", true, {issue: payload});
    }

    getTicketDetails(id: number): Observable<any> {
      return this.get("/api/tickets/" + id, true);
    }

    editTicket(id: number, payload: any): Observable<any> {
      return this.put("/api/tickets/" + id, true, {issue: payload});
    }

    addNewIncident(payload: any): Observable<any> {
      return this.post("/api/incidents", true, {issue: payload});
    }

    getIncidentDetails(id: number): Observable<any> {
      return this.get("/api/incidents/" + id, true);
    }

    editIncident(id: number, payload: any): Observable<any> {
      return this.put("/api/incidents/" + id, true, {issue: payload});
    }

    private getMapContacts(contactItem: any): ContactInfo {
        const contact = new ContactInfo();
        contact.id = contactItem.id;
        if (contactItem.addresses && contactItem.addresses !== null && contactItem.addresses.length > 0) {
            contact.address = contactItem.addresses;
        }
        if (contactItem.avatar) {
            contact.avatar = contactItem.avatar;
        }
        if (contactItem.company) {
            contact.company = contactItem.company;
        }
        if (contactItem.created_at && contactItem.created_at !== null) {
            contact.created_at = new Date(contactItem.created_at);
        }
        if (contactItem.deleted_at && contactItem.deleted_at !== null) {
            contact.deleted_at = new Date(contactItem.deleted_at);
        }
        if (contactItem.emails) {
            contact.emails = contactItem.emails;
        }
        if (contactItem.first_name) {
            contact.firstName = contactItem.first_name;
        }
        if (contactItem.groups) {
            contact.groups = contactItem.groups;
        }
        if (contactItem.is_company) {
            contact.is_company = contactItem.is_company === "true" ? true : false;
        }
        if (contactItem.is_global) {
            contact.is_global = contactItem.is_global === "true" ? true : false;
        }
        if (contactItem.jid) {
            contact.jid = contactItem.jid;
        }
        if (contactItem.job_title) {
            contact.jobTitle = contactItem.job_title;
        }
        if (contactItem.last_name) {
            contact.lastName = contactItem.last_name;
        }
        if (contactItem.middle_name) {
            contact.middleName = contactItem.middle_name;
        }
        if (contactItem.phones) {
            contact.phones = contactItem.phones;
        }
        if (contactItem.updated_at && contactItem.updated_at !== null) {
            contact.updated_at = new Date(contactItem.updated_at);
        }
        if (contactItem.is_global) {
            contact.is_global = contactItem.is_global === "true" ? true : false;
        }
        if (contactItem.notes) {
            contact.notes = contactItem.notes;
        }
        if (contactItem.im_accounts && contactItem.im_accounts !== null && contactItem.im_accounts.length > 0) {
            contact.im_accounts = contactItem.im_accounts;
        }
        if (contactItem.urls) {
            contact.urls = contactItem.urls;
        }
        if (contactItem.custom_fields) {
            contact.custom_fields = contactItem.custom_fields;
        }
        if (contactItem.events && contactItem.events !== null && contactItem.events.length > 0) {
            contact.events = contactItem.events;
        }
        if (contactItem.contact_lists) {
            contact.contact_list = contactItem.contact_lists;
        }
        if (contactItem.tags) {
            contact.tags = contactItem.tags;
        }
        if (contactItem.time_zone && contactItem.time_zone !== null) {
            contact.timezone = contactItem.time_zone;
        }
        if (contactItem.language && contactItem.language !== null) {
            contact.language = contactItem.language;
        }
        if (contactItem.skills && contactItem.skills !== null && contactItem.skills.length > 0) {
            contact.skills = contactItem.skills;
        }
        if (contactItem.interests && contactItem.interests !== null && contactItem.interests.length > 0) {
            contact.interests = contactItem.interests;
        }
        if (contactItem.birthday && contactItem.birthday !== null) {
            contact.birthday = new Date(contactItem.birthday);
        }
        if (contactItem.gender && contactItem.gender !== null) {
            contact.gender = contactItem.gender;
        }
        if (contactItem.marital_status && contactItem.marital_status !== null) {
            contact.marital_status = contactItem.marital_status;
        }
        if (contactItem.private_email && contactItem.private_email !== null) {
            contact.private_email = contactItem.private_email;
        }
        if (contactItem.start_date && contactItem.start_date !== null) {
            contact.start_date = new Date(contactItem.start_date);
        }
        if (contactItem.end_date && contactItem.end_date !== null) {
            contact.end_date = new Date(contactItem.end_date);
        }
        if (contactItem.per_week_availability && contactItem.per_week_availability !== null) {
            contact.per_week_availability = contactItem.per_week_availability;
        }
        if (contactItem.hourly_rate && contactItem.hourly_rate !== null) {
            contact.hourly_rate = contactItem.hourly_rate;
        }
        if (!!contactItem.vnc_employee && contactItem.vnc_employee !== null) {
            contact.vnc_employee = contactItem.vnc_employee;
        }
        if (contactItem.payment_mode && contactItem.payment_mode !== null) {
            contact.payment_mode = contactItem.payment_mode;
        }
        if (contactItem.passport_expiry && contactItem.passport_expiry !== null) {
            contact.passport_expiry = new Date(contactItem.passport_expiry);
        }
        if (contactItem.rfc_limit && contactItem.rfc_limit !== null) {
            contact.rfc_limit = contactItem.rfc_limit;
        }
        if (contactItem.username && contactItem.username !== null) {
            contact.username = contactItem.username;
        }
        if (contactItem.admin && contactItem.admin !== null) {
            contact.admin = contactItem.admin;
        }
        if (contactItem.agb_accepted && contactItem.agb_accepted !== null) {
            contact.agb_accepted = contactItem.agb_accepted;
        }
        if (contactItem.video_bridge && contactItem.video_bridge !== null) {
            contact.video_bridge = contactItem.video_bridge;
        }
        if (contactItem.omemo && contactItem.omemo !== null) {
            contact.omemo = contactItem.omemo;
        }
        if (contactItem.id_number && contactItem.id_number !== null) {
            contact.national_id_number = contactItem.id_number;
        }
        if (contactItem.id_expiry && contactItem.id_expiry !== null) {
            contact.national_id_expiry = new Date(contactItem.id_expiry);
        }
        if (contactItem.products && contactItem.products !== null && contactItem.products.length > 0) {
            contact.products = contactItem.products;
        }
        if (contactItem.favorite && contactItem !== null) {
            contact.favorite = contactItem.favorite === "true" ? true : false;
        }
        contact.fullName = this.getFullName(contact.firstName, contact.lastName);
        contact.bgAvatarColor = CommonUtils.getRandomAvatarColor();
        return contact;
    }
    private getFullName(firstName: string, lastName: string): string {
        if (firstName && lastName) {
            return firstName + " " + lastName;
        } else {
            if (firstName && firstName !== undefined) {
                return firstName;
            } else if (lastName && lastName !== undefined) {
                return lastName;
            }
        }
    }

    getSuggestions(query: string): Observable<any[]> {
      const data = {query};
      return this.post<any>(`/api/suggest`, true, data).pipe(
        map(v => {
          const vncSuggest = v.suggest.vncSuggest;
          if (!!vncSuggest && vncSuggest[query]) {
            return vncSuggest[query].suggestions;
          } else {
            return [];
          }
        })
      );
    }

    saveFCMToken(token: string, email: string) {
      let body = {
        email: email,
        token: token,
      };
      return this.post(`/api/search/saveToken`, true, body);
    }

    getEnglishJson() {
      return this.http.get("assets/i18n/en.json");
    }

    getGermanJson() {
      return this.http.get("assets/i18n/de.json");
    }
}
