/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Action } from "../actions";
import { AppActionTypes } from "../actions/app";
import { UserProfile } from "../common/models/mail-models/user-profile";
import { Message } from "../common/models/message.model";
import { MessageActionTypes } from "../actions/message";
import { Tag } from "../common/models/tag.model";
import { Moment } from "moment";
import * as moment from "moment";

export interface AppState {
  showFilters: boolean;
  activeApps: string[];
  loggedInUserLoading: boolean;
  isSidebarExpanded: boolean;
  userProfile: UserProfile;
  isOnline: boolean;
  isLoggedIn: boolean;
  isDeviceReady: boolean;
  isActionProcessing: boolean;
  federatedApps: any[];
  firebaseToken?: string;
  photoUpdate: any;
  unreadOnly: boolean;
  hideAllComments: boolean;
  viewBy: string;
  orderBy: string;
  groupBy: string;
  searchString: string;
  totalPerPage: number;
  countForApps: any;
  unreadCountForApps: any;
  notificationSettings: {[app: string]: boolean};
  language: string;
  messageToReply: Message;
  userContacts: any[];
  userAllGalContacts: any[];
  isXmppConnected: boolean;
  networkInformation: any;
  bare: string;
  tags: Tag[];
  filters: any;
  projects: any[];
  statuses: any[];
  priorities: any[];
  dateRange: {startDate: Moment, endDate: Moment};
}

export const initialState: AppState = {
  loggedInUserLoading: false,
  isSidebarExpanded: false,
  userProfile: null,
  isOnline: false,
  isDeviceReady: false,
  isLoggedIn: false,
  isActionProcessing: false,
  federatedApps: [],
  firebaseToken: "",
  photoUpdate: {},
  unreadOnly: true,
  hideAllComments: false,
  viewBy: "detail",
  orderBy: "created_dt desc",
  groupBy: "none",
  searchString: "",
  activeApps: ["talk", "mail", "task"],
  totalPerPage: 20,
  countForApps: {},
  unreadCountForApps: {},
  notificationSettings: {},
  language: "en",
  messageToReply: null,
  userContacts: [],
  userAllGalContacts: [],
  isXmppConnected: false,
  networkInformation: null,
  bare: null,
  tags: [],
  projects: [],
  statuses: [],
  priorities: [],
  showFilters: false,
  filters: [],
  dateRange: {
    // startDate: moment().subtract(30, "days").set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0}),
    // endDate: moment()
    startDate: null,
    endDate: null
  }
};

export function appReducer(state: AppState = initialState, action: Action): AppState {
  switch (action.type) {

    case AppActionTypes.LOGIN_REQUEST: {
      return {
        ...state,
        loggedInUserLoading: true
      };
    }

    case AppActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true
      };
    }

    case AppActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        isLoggedIn: false
      };
    }

    case AppActionTypes.SET_SIDEBAR_STATUS: {
      return {
        ...state,
        isSidebarExpanded: action.payload
      };
    }

    case AppActionTypes.APP_ONLINE: {
      return {
        ...state,
        isOnline: action.payload
      };
    }

    case AppActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        isLoggedIn: true,
        userProfile: action.payload
      };
    }

    case AppActionTypes.SET_UNREAD_ONLY: {
      return {
        ...state,
        unreadOnly: action.payload
      };
    }

    case AppActionTypes.SET_HIDE_ALL_COMMENTS: {
      return {
        ...state,
        hideAllComments: action.payload
      };
    }

    case AppActionTypes.SET_ORDER_BY: {
      return {
        ...state,
        orderBy: action.payload
      };
    }
    case AppActionTypes.SET_GROUP_BY: {
      return {
        ...state,
        groupBy: action.payload
      };
    }

    case AppActionTypes.SET_VIEW_BY: {
      return {
        ...state,
        viewBy: action.payload
      };
    }

    case AppActionTypes.SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: action.payload
      };
    }

    case AppActionTypes.DEVICE_READY: {
      return {
        ...state,
        isDeviceReady: true
      };
    }

    case AppActionTypes.XMPP_CONNECTED: {
      const response = action.payload;
      return {
        ...state,
        isXmppConnected: action.payload
      };
    }

    case AppActionTypes.UPDATE_CONNECTION_INFORMATION: {
      return {
        ...state,
        networkInformation: {
          ...state.networkInformation,
          ...action.payload
        }
      };
    }

    case AppActionTypes.SET_BARE: {
      return {
        ...state,
        bare: action.payload
      };
    }

    case AppActionTypes.SET_TAGS: {
      return {
        ...state,
        tags: action.payload
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.appState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case AppActionTypes.START_PROCESSING: {
      return {
        ...state,
        isActionProcessing: true
      };
    }

    case AppActionTypes.STOP_PROCESSING: {
      return {
        ...state,
        isActionProcessing: false
      };
    }

    case AppActionTypes.SET_FIREBASE_TOKEN: {
      return {
        ...state,
        firebaseToken: action.payload
      };
    }

    case AppActionTypes.SET_LAST_PHOTO_UPDATE: {
      let photoUpdate = { ...state.photoUpdate } || {};
      photoUpdate[action.payload.email] = action.payload.timestamp;
      localStorage.setItem("lastPhotoUpdate", JSON.stringify(photoUpdate));
      return {
        ...state,
        photoUpdate: photoUpdate
      };
    }

    case AppActionTypes.TOGGLE_ACTIVE_APP: {
      let activeApps = [...state.activeApps];
      if (activeApps.indexOf(action.payload) === -1) {
        activeApps.push(action.payload);
      } else {
        activeApps = activeApps.filter(val => val !== action.payload);
      }
      return {
        ...state,
        activeApps: activeApps
      };
    }

    case AppActionTypes.SET_ACTIVE_APP: {
      return {
        ...state,
        activeApps: action.payload
      };
    }

    case AppActionTypes.SET_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: action.payload
      };
    }

    case AppActionTypes.SET_COUNT_FOR_APPS: {
      return {
        ...state,
        countForApps: action.payload
      };
    }

    case AppActionTypes.SET_UNREAD_COUNT_FOR_APPS: {
      return {
        ...state,
        unreadCountForApps: action.payload
      };
    }

    case AppActionTypes.RESET_LAST_PHOTO_UPDATE: {
      return {
        ...state,
        photoUpdate: {}
      };
    }

    case AppActionTypes.RESTORE_LAST_PHOTO_UPDATE: {
      return {
        ...state,
        photoUpdate: action.payload
      };
    }

    case AppActionTypes.SET_AVAILABLE_APPS: {
     
      return {
        ...state,
        federatedApps: action.payload
      };
    }

    case AppActionTypes.SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      };
    }

    case MessageActionTypes.SET_MESSAGE_TO_REPLY: {
      return {
        ...state,
        messageToReply: action.payload
      };
    }

    case AppActionTypes.SET_USER_CONTACTS: {
      return {
        ...state,
        userContacts: action.payload
      };
    }

    case AppActionTypes.SET_USER_CONTACTS: {
      return {
        ...state,
        userAllGalContacts: action.payload
      };
    }

    case AppActionTypes.SET_TOTAL_PER_PAGE: {
      return {
        ...state,
        totalPerPage: action.payload
      };
    }

    case AppActionTypes.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload
      };
    }

    case AppActionTypes.SET_SHOW_FILTERS: {
      return {
        ...state,
        showFilters: action.payload
      };
    }

    case AppActionTypes.SET_ADVANCED_SEARCH: {
      return {
        ...state,
        filters: action.payload
      };
    }

    case AppActionTypes.SET_PROJECTS: {
      return {
        ...state,
        projects: action.payload
      };
    }

    case AppActionTypes.SET_PRIORITIES: {
      return {
        ...state,
        priorities: action.payload
      };
    }

    case AppActionTypes.SET_STATUSES: {
      return {
        ...state,
        statuses: action.payload
      };
    }

    case AppActionTypes.SET_PROJECT_DATA: {
      return {
        ...state,
        statuses: action.payload.statuses,
        projects: action.payload.projects,
        priorities: action.payload.priorities
      };
    }

    case AppActionTypes.DEVICE_READY: {
      return {
         ...state,
          isDeviceReady: true
      };
    }
    default: {
      return state;
    }
  }
}

export const _getIsLoggedInUserLoading = (state: AppState) => state.loggedInUserLoading;
export const _getIsActionProcessing = (state: AppState) => state.isActionProcessing;
export const _getSidebarStatus = (state: AppState) => state.isSidebarExpanded;
export const _getUserProfile = (state: AppState) => state.userProfile;
export const _IsDeviceReady = (state: AppState) => state.isDeviceReady;
export const _getOnlineStatus = (state: AppState) => state.isOnline;
export const _getIsLoggedIn = (state: AppState) => state.isLoggedIn;
export const _getFederatedApps = (state: AppState) => state.federatedApps;
export const _getAppsCount = (state: AppState) => state.countForApps;
export const _getAppsUnreadCount = (state: AppState) => state.unreadCountForApps;
export const _getFirebaseToken = (state: AppState) => state.firebaseToken;
export const _getLastPhotoUpdate = (state: AppState) => state.photoUpdate;
export const _getUnreadOnly = (state: AppState) => state.unreadOnly;
export const _getHideAllComments = (state: AppState) => state.hideAllComments;
export const _getViewBy = (state: AppState) => state.viewBy;
export const _getGroupBy = (state: AppState) => state.groupBy;
export const _getOrderBy = (state: AppState) => state.orderBy;
export const _getSearchString = (state: AppState) => state.searchString;
export const _getActiveApps = (state: AppState) => state.activeApps;
export const _getNotificationSettings = (state: AppState) => state.notificationSettings;
export const _getLanguage = (state: AppState) => state.language;
export const _getLastPhotoUpdateByEmail = (state: AppState, email: string) => state.photoUpdate[email];
export const _getMessageToReply = (state: AppState) => state.messageToReply;
export const _getUserContacts = (state: AppState) => state.userContacts;
export const _getUserGalContacts = (state: AppState) => state.userAllGalContacts;
export const _getIsConnectedXMPP = (state: AppState) => state.isXmppConnected;
export const _getNetworkInformation = (state: AppState) => state.networkInformation;
export const _getTags = (state: AppState) => state.tags;
export const _getTotalPerPage = (state: AppState) => state.totalPerPage;
export const _getDateRange = (state: AppState) => state.dateRange;
export const _getShowFilters = (state: AppState) => state.showFilters;
export const _getFilters = (state: AppState) => state.filters;
export const _getProjectData = (state: AppState) => {
  return {projects: state.projects, statuses: state.statuses, priorities: state.priorities};
 };

