/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { BrowserModule, HammerGestureConfig, Title } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule, AuthGuard } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./app.effects";
import { DefaultRouterStateSerializer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CommonUtils } from "./common/utils/common-util";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { reducers, reset } from "./reducers";
import { AuthService } from "./common/providers/auth.service";
import { ConfigService } from "./config.service";
import { AvailableRoutes } from "./common";
import { ResponsiveService } from "./common/providers/responsive.service";
import { ErrorService } from "./common/providers/error-service";
import { ToastService } from "./common/providers/toast.service";
import { CommonModule, DatePipe } from "@angular/common";
import { ElectronService } from "./services/electron.service";
import { SuccessService } from "./common/providers/sucess-service";
import { Broadcaster } from "./common/providers/broadcaster.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { SharedModule } from "./shared/shared.module";
import { HeaderComponent } from "./header/header.component";
import { PageNotFoundComponent } from "./shared/components/page-not-found/page-not-found.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { DocumentListComponent } from "./document-list/document-list.component";
import { DocPreviewComponent } from "./doc-preview/doc-preview.component";
import { AppService } from "./services/app.service";
import { QuickPreviewComponent } from "./quick-preview/quick-preview.component";
import { AppRepository } from "./repositories/app.repository";
import { CondensedViewComponent } from "./condensed-view/condensed-view.component";
import { SearchComponent } from "./search/search.component";
import { ProfileComponent } from "./profile/profile.component";
import { StartChatComponent } from "./start-chat/start-chat.component";
import { httpInterceptorProviders } from "./http-interceptors";
import { ChatPreviewComponent } from "./chat-preview/chat-preview.component";
import { MessageComponent } from "./message/message.component";
import { VNCAuthenticationComponent } from "./vnc-authentication";
import { ForwardMessageComponent } from "./forward-message/forward-message.component";
import { EmailToFriendComponent } from "./email-to-friend/email-to-friend.component";
import { ComposeMailComponent } from "src/app/compose-mail/compose-mail.component";
import { DragDropParticipantService } from "src/app/common/providers/drag-drop-participant.service";
import { XmppService } from "./services/xmpp.service";
import { SelectActivityDialogComponent } from "./select-activity-dialog/select-activity-dialog.component";
import { StartGroupChatComponent } from "./start-group-chat/start-group-chat.component";
import { UpdateAvatarComponent } from "./update-avatar/update-avatar.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { MailOperationComposeComponent } from "src/app/mail-operation-compose/mail-operation-compose.component";
import { CreateNewDialogComponent } from "./create-new-dialog/create-new-dialog.component";
import { ContactListComponent } from "./contacts-list/contacts-list.component";
import { MetaCommentComponent } from "./meta-comment/meta-comment.component";
import { AdvancedSearchDialogComponent } from "./advanced-search-dialog/advanced-search-dialog.component";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { FiltersSidebarComponent } from "./filters-sidebar/filters-sidebar.component";
import { ComposeTaskComponent } from "./task-compose/compose-task.component";
import { ComposeTaskInputComponent, TaskAddDescriptionComponent, TaskOptionComponent } from "./task-compose";
import { MatCardModule } from "@angular/material/card";
import { TaskStartDateOptionComponent } from "./task-compose/task-options/option-start-date/option-start-date.component";
import { LocaleService } from "./common/providers/locale.service";
import { TaskDueDateOptionComponent } from "./task-compose/task-options/option-due-date/option-due-date.component";
import { TaskPriorityOptionComponent } from "./task-compose/task-options/option-priority/option-priority.component";
import { TaskLinkProjectOptionComponent } from "./task-compose/task-options/option-link-project/option-link-project.component";
import { TaskAddUserOptionComponent } from "./task-compose/task-options/option-add-user/option-add-user.component";
import { TaskAddRepeatsComponent } from "./task-compose/task-options/option-add-repeats/option-add-repeats.component";
import { TaskAddEstimateComponent } from "./task-compose/task-options/option-add-estimate/option-add-estimate.component";
import { TaskAddTagsOptionComponent } from "./task-compose/task-options/option-add-tags/option-add-tags.component";
import { TaskPreviewComponent } from "./task-preview/task-preview.component";
import { AdvSearchDialogComponent } from "./adv-search-dialog/adv-search-dialog.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { SelectParticipantGroupChatComponent } from "src/app/select-participant-group-chat/select-participant-group-chat.component";
import { DateAdapter, VpCalendarModule } from "./shared/components/vp-calendar/vp-calendar.module";
import { adapterFactory } from "./shared/components/vp-calendar/date-adapters/date-fns";
import { DocMailPreviewComponent } from "./doc-preview/doc-mail-preview/doc-mail-preview.component";
import { DocRepliesComponent } from "./doc-preview/doc-replies/doc-replies.component";
import { DocTaskPreviewComponent } from "./doc-preview/doc-task-preview/doc-task-preview.component";
import { DocTalkPreviewComponent } from "./doc-preview/doc-talk-preview/doc-talk-preview.component";
import { SearchInfoDialogComponent } from "./adv-search-dialog/search-info-dialog/search-info-dialog.component";
import { MultiFilterWithSearchComponent } from "./adv-search-dialog/multi-filter-with-search/multi-filter-with-search.component";
import { UsersFilterComponent } from "./document-list/users-filter/users-filter.component";
// import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// import { environment } from "src/environments/environment";
import { IconsService } from "./common/providers/icons.service";
import { ComposeTicketComponent } from "./compose-ticket/compose-ticket.component";
import { TicketPreviewComponent } from "./ticket-preview/ticket-preview.component";
import { ComposeIncidentComponent } from "./compose-incident/compose-incident.component";
import { IncidentPreviewComponent } from "./incident-preview/incident-preview.component";
import { DocAddTagComponent } from "./doc-preview/doc-add-tag/doc-add-tag.component";
import { ColorControlDialogComponent } from "./doc-preview/doc-add-tag/color-control-dialog/color-control-dialog.component";
import { ColorChromeModule } from "ngx-color/chrome";
import { DocTagsTooltipComponent } from "./doc-preview/doc-tags-tooltip/doc-tags-tooltip.component";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "../environments/environment";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import * as Sentry from "@sentry/browser";

export function HttpLoaderFactory(http: HttpClient) {
  const themeHash = localStorage.getItem("themeHash");
  return new TranslateHttpLoader(http, CommonUtils.getBaseUrl() + "/assets/i18n/", ".json?v=" + themeHash);
}


let EXTRA_PROVIDERS = [];

export class BaluHammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}

export class SentryErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      Sentry.captureException(error.originalError || error);
    } catch (e) {
      console.error(e);
    }
  }
}

if (environment.production) {
  Sentry.init({ dsn: environment.sentryUrl });
  EXTRA_PROVIDERS = [{ provide: ErrorHandler, useClass: SentryErrorHandler }];
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageNotFoundComponent,
    SidebarComponent,
    ContactListComponent,
    DocumentListComponent,
    DocPreviewComponent,
    CondensedViewComponent,
    QuickPreviewComponent,
    SearchComponent,
    ProfileComponent,
    StartChatComponent,
    ComposeMailComponent,
    ForwardMessageComponent,
    ChatPreviewComponent,
    StartGroupChatComponent,
    UpdateAvatarComponent,
    SelectActivityDialogComponent,
    MessageComponent,
    VNCAuthenticationComponent,
    EmailToFriendComponent,
    MailOperationComposeComponent,
    CreateNewDialogComponent,
    EmailToFriendComponent,
    AdvancedSearchDialogComponent,
    AdvSearchDialogComponent,
    MultiFilterWithSearchComponent,
    SearchInfoDialogComponent,
    FiltersSidebarComponent,
    MetaCommentComponent,
    ComposeTaskComponent,
    ComposeTaskInputComponent,
    TaskOptionComponent,
    TaskAddDescriptionComponent,
    TaskStartDateOptionComponent,
    TaskDueDateOptionComponent,
    TaskPriorityOptionComponent,
    TaskLinkProjectOptionComponent,
    TaskAddUserOptionComponent,
    TaskAddRepeatsComponent,
    TaskAddEstimateComponent,
    TaskAddTagsOptionComponent,
    TaskPreviewComponent,
    SelectParticipantGroupChatComponent,
    DocMailPreviewComponent,
    DocTalkPreviewComponent,
    DocTaskPreviewComponent,
    DocRepliesComponent,
    DocAddTagComponent,
    DocTagsTooltipComponent,
    ColorControlDialogComponent,
    UsersFilterComponent,
    MetaCommentComponent,
    ComposeTicketComponent,
    TicketPreviewComponent,
    ComposeIncidentComponent,
    IncidentPreviewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ImageCropperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    MatNativeDateModule,
    SharedModule.forRoot(),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({serializer: DefaultRouterStateSerializer}),
    StoreModule.forRoot(reducers, {
      metaReducers: [reset],
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    MatRippleModule,
    MatCardModule,
    MatExpansionModule,
    VpCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ColorChromeModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // !environment.production ? StoreDevtoolsModule.instrument({maxAge: 50}) : []
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    httpInterceptorProviders,
    Title,
    AuthService,
    ConfigService,
    AuthGuard,
    AvailableRoutes,
    ResponsiveService,
    ErrorService,
    ToastService,
    Broadcaster,
    ElectronService,
    ErrorService,
    SuccessService,
    ResponsiveService,
    XmppService,
    AppService,
    AppRepository,
    DatePipe,
    TranslatePipe,
    DragDropParticipantService,
    LocaleService,
    IconsService,
    ...EXTRA_PROVIDERS],
  entryComponents: [
    QuickPreviewComponent,
    ProfileComponent,
    StartChatComponent,
    ComposeMailComponent,
    ComposeTicketComponent,
    ForwardMessageComponent,
    EmailToFriendComponent,
    SelectActivityDialogComponent,
    StartGroupChatComponent,
    UpdateAvatarComponent,
    ChatPreviewComponent,
    MailOperationComposeComponent,
    CreateNewDialogComponent,
    AdvancedSearchDialogComponent,
    AdvSearchDialogComponent,
    SearchInfoDialogComponent,
    ChatPreviewComponent,
    SelectParticipantGroupChatComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
