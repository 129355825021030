/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";

import { getContentEditableCaretCoords, isInputOrTextAreaElement } from "./mention-utils";
import { getCaretCoordinates } from "./caret-coords";

@Component({
  selector: "vp-mention-list",
  template: `
    <ng-template #defaultItemTemplate let-item="item">
      <div class="mention-full-name">{{ item.label }}</div>
      <span class="mention-bare">{{ item.value }}</span>
    </ng-template>
    <ul #list [hidden]="hidden" class="dropdown-menu scrollable-menu">
        <li *ngFor="let item of items; let i = index" [class.active]="activeIndex==i">
            <a class="dropdown-item" (click)="activeIndex=i;itemClick.emit();$event.preventDefault();$event.stopPropagation();"
            (mousedown)="activeIndex=i;itemClick.emit();$event.preventDefault();$event.stopPropagation();">
              <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{'item':item}"></ng-template>
            </a>
        </li>
    </ul>
    `
})
export class MentionListComponent implements OnInit {
  @Input() labelKey: string = "label";
  @Input() itemTemplate: TemplateRef<any>;
  @Output() itemClick = new EventEmitter();
  @ViewChild("list", {static: true}) list: ElementRef;
  @ViewChild("defaultItemTemplate", {static: true}) defaultItemTemplate: TemplateRef<any>;
  items = [];
  activeIndex: number = 0;
  hidden: boolean = false;
  @HostBinding("class") className = "";
  constructor(private element: ElementRef) { }

  ngOnInit() {
    if (!this.itemTemplate) {
      this.itemTemplate = this.defaultItemTemplate;
    }
  }

  destroy(): void {
    this.element.nativeElement.remove();
  }

  // lots of confusion here between relative coordinates and containers
  position(nativeParentElement: HTMLInputElement, iframe: HTMLIFrameElement = null, dropUp: boolean) {
    let coords = { top: 0, left: 0 };
    if (isInputOrTextAreaElement(nativeParentElement)) {
      // parent elements need to have postition:relative for this to work correctly?
      coords = getCaretCoordinates(nativeParentElement, nativeParentElement.selectionStart);
      coords.top = nativeParentElement.offsetTop + coords.top + 16;
      coords.left = nativeParentElement.offsetLeft + coords.left;
    } else if (iframe) {
      let context: { iframe: HTMLIFrameElement, parent: Element } = { iframe: iframe, parent: iframe.offsetParent };
      coords = getContentEditableCaretCoords(context);
    } else {
      let doc = document.documentElement;
      let scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      let scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

      // bounding rectangles are relative to view, offsets are relative to container?
      let caretRelativeToView = getContentEditableCaretCoords({ iframe: iframe });
      let parentRelativeToContainer: ClientRect = nativeParentElement.getBoundingClientRect();

      coords.top = caretRelativeToView.top - parentRelativeToContainer.top + nativeParentElement.offsetTop - scrollTop;
      coords.left = caretRelativeToView.left - parentRelativeToContainer.left + nativeParentElement.offsetLeft - scrollLeft;
    }
    let el: HTMLElement = this.element.nativeElement;
    if (this.list) {
      this.list.nativeElement.style.marginBottom = dropUp ? "24px" : null;
    }
    el.className = dropUp ? "dropup" : "mentions-list-wrapper";
    el.style.position = "absolute";
    el.style.left = coords.left + "px";
    el.style.top = "-150px";
    if (window.outerWidth < 767) {
      el.style.left = "0px";
      el.style.top = "-175px";
    }
  }

  get activeItem() {
    return this.items[this.activeIndex];
  }

  activateNextItem() {
    // adjust scrollable-menu offset if the next item is out of view
    if (this.list) {
      let listEl: HTMLElement = this.list.nativeElement;
      let activeEl = listEl.getElementsByClassName("active").item(0);
      if (activeEl) {
        let nextLiEl: HTMLElement = <HTMLElement>activeEl.nextSibling;
        if (nextLiEl && nextLiEl.nodeName === "LI") {
          let nextLiRect: ClientRect = nextLiEl.getBoundingClientRect();
          if (nextLiRect.bottom > listEl.getBoundingClientRect().bottom) {
            listEl.scrollTop = nextLiEl.offsetTop + nextLiRect.height - listEl.clientHeight;
          }
        }
      }
      // select the next item
      this.activeIndex = Math.max(Math.min(this.activeIndex + 1, this.items.length - 1), 0);
    }
  }

  activatePreviousItem() {
    // adjust the scrollable-menu offset if the previous item is out of view
    if (this.list) {
      let listEl: HTMLElement = this.list.nativeElement;
      let activeEl = listEl.getElementsByClassName("active").item(0);
      if (activeEl) {
        let prevLiEl: HTMLElement = <HTMLElement>activeEl.previousSibling;
        if (prevLiEl && prevLiEl.nodeName === "LI") {
          let prevLiRect: ClientRect = prevLiEl.getBoundingClientRect();
          if (prevLiRect.top < listEl.getBoundingClientRect().top) {
            listEl.scrollTop = prevLiEl.offsetTop;
          }
        }
      }
      // select the previous item
      this.activeIndex = Math.max(Math.min(this.activeIndex - 1, this.items.length - 1), 0);
    }
  }

  resetScroll() {
    if (this.list) {
      this.list.nativeElement.scrollTop = 0;
    }
  }
}
