/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AppRepository } from "../repositories/app.repository";
import { Store } from "@ngrx/store";
import { getContacts, getOnlineStatus, getUserProfile, RootState } from "../reducers";
import { distinctUntilChanged, filter, map, skip, startWith, take, takeUntil, tap } from "rxjs/operators";
// import * as _ from "lodash";
import orderBy from "lodash/orderBy";
import { UserProfile } from "../common/models/mail-models";
import { FormControl, FormGroup } from "@angular/forms";
import { ToastService } from "../common/providers/toast.service";
import { Broadcaster } from "../common/providers/broadcaster.service";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MediaType } from "../common/models/vnctalk.model";
import { TranslatePipe } from "@ngx-translate/core";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { AdvSearchFilter } from "../common/models/adv-search-filter.model";
import { Moment } from "moment";
import * as moment from "moment";
import { ActivatedRoute, Router } from "@angular/router";
import isEqual from "lodash/isEqual";

@Component({
  selector: "vp-filters-sidebar",
  templateUrl: "./filters-sidebar.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  isAppOnline: boolean;
  talkURL = "";
  currentUser: UserProfile;
  searchKeyword = new FormControl("");

  searchMode = "advanced";
  @ViewChild("advancedSearchDialog") advancedSearchDialog: ElementRef;
  @ViewChild("advancedForm") advancedForm: ElementRef;
  private isAlive$ = new Subject<boolean>();
  availableApps: any[] = [];
  includedApps = {};
  supportedApps = ["mail", "task", "talk"];
  talkFiltersOpen: boolean = true;
  mailFiltersOpen: boolean = true;
  taskFiltersOpen: boolean = true;
  showOnlyUnreadCtrl = new FormControl(false);
  starredOnlyCtrl = new FormControl(false);
  hasAttachmentsCtrl = new FormControl(false);
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl("");
  filteredTags: any[];
  tags: any[] = [];
  lang = "en";
  selected: {startDate: Moment, endDate: Moment};
  showAllAsDefault = true;

  projects: any[] = [];
  statuses: any[] = [];
  priorities: any[] = [];
  contacts: any[] = [];
  mailFolders: any[] = [];
  mailStatuses: {name: string, value: string}[] = [
    {name: this.translatePipe.transform("READ") , value: "READ"},
    {name: this.translatePipe.transform("UNREAD"), value: "UNREAD"},
    {name: this.translatePipe.transform("FLAGGED"), value: "FLAGGED"},
    {name: this.translatePipe.transform("NOT_FLAGGED"), value: "NOT_FLAGGED"},
    // {name: this.translatePipe.transform("DRAFT"), value: "DRAFT"},
    {name: this.translatePipe.transform("SENT_BY_ME"), value: "SENT_BY_ME"},
    {name: this.translatePipe.transform("RECEIVED_BY_ME"), value: "RECEIVED_BY_ME"},
    // {name: this.translatePipe.transform("REPLIED_BY_ME"), value: "REPLIED_BY_ME"},
  ];

  // attachmentTypes: {name: string, value: string}[] = [
  //   {name: this.translatePipe.transform("ADOBE_PDF"), value: MediaType.PDF},
  //   // {name: "APPLICATION", value: MediaType.ATTACHMENTS},
  //   {name: this.translatePipe.transform("AUDIO"), value: MediaType.VOICE_MESSAGE},
  //   // {name: "HTML", value: MediaType.ZIP},
  //   {name: this.translatePipe.transform("IMAGE"), value: MediaType.IMAGE},
  //   // {name: "MAIL_MESSAGE", value: MediaType.ATTACHMENTS},
  //   {name: this.translatePipe.transform("TEXT_DOCUMENTS"), value: MediaType.DOC},
  //   {name: this.translatePipe.transform("TEXT_CALENDAR"), value: MediaType.TEXT},
  //   {name: this.translatePipe.transform("VIDEO"), value: MediaType.VIDEOS},
  //   {name: this.translatePipe.transform("ZIP_FILE"), value: MediaType.ZIP},
  // ];

  talkFilters = new FormGroup({
    insideAttachments: new FormControl(false),
    // insideTickets: new FormControl(false),
    sender: new FormControl([]),
  });

  mailFilters = new FormGroup({
    receivedFrom: new FormControl([]),
    sentTo: new FormControl([]),
    // attachmentType: new FormControl([]),
    status: new FormControl([]),
    folder: new FormControl([]),
  });

  taskFilters = new FormGroup({
    assignedTo: new FormControl([]),
    // project: new FormControl([]),
    status: new FormControl([]),
    priority: new FormControl([]),
  });

  feedFilters = {};
  talkAllSendersSelected = true;
  taskAllAssignedSelected = true;
  taskAllProjectsSelected = true;
  taskAllStatusSelected = true;
  taskAllPrioritySelected = true;
  mailAllReceivedFromSelected = true;
  mailAllSentToSelected = true;
  mailAllAttachmentSelected = true;
  mailAllStatusSelected = true;
  mailAllFoldersSelected = true;

  isTalkFiltersChange: boolean = false;
  isMailFiltersChange: boolean = false;
  isTaskFiltersChange: boolean = false;

  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;

  constructor(
    private activeRoute: ActivatedRoute,
    private appRepository: AppRepository,
    private toastService: ToastService,
    private broadcaster: Broadcaster,
    private router: Router,
    private store: Store<RootState>,
    private translatePipe: TranslatePipe,
    private changeDetectorRef: ChangeDetectorRef) {
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getLanguage().pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
      this.lang = lang;
    });
    this.selected = {
      startDate: moment().subtract(30, "days").set({hours: 0, minutes: 0}),
      endDate: moment()
    };
    this.appRepository.getSearchString().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      const prevValue = this.searchKeyword.value;
      if (prevValue !== val) {
       
        this.searchKeyword.setValue(val);
      }
    });
    this.appRepository.getFederatedApps().pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.availableApps = apps.filter(v => this.supportedApps.includes(v.appKey));
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getProjectData().pipe(takeUntil(this.isAlive$)).subscribe(v => {
      this.projects = v.projects.map( p => ({name: p.name, value: p.id}));
      this.statuses = v.statuses.map( s => ({name: s.name, value: s.name}));
      this.priorities = v.priorities.map( p => ({name: p.name, value: p.name}));
      this.changeDetectorRef.markForCheck();
    });
    this.appRepository.getMailFolders().pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (!!v && !!v.folder) {
        this.mailFolders = v.folder[0].folder.map( f => ({name: f.name, value: f.id}));
        this.changeDetectorRef.markForCheck();
      }
    });
    this.store.select(getContacts).pipe(takeUntil(this.isAlive$)).subscribe(contacts => {
      this.contacts = orderBy(contacts, ["name"] , ["asc"]).map( p => ({name: p.name, value: p.defaultMail}));
      this.changeDetectorRef.markForCheck();
    });

    const params = this.activeRoute.snapshot.queryParams;
    if (!!params.q) {
      this.searchKeyword.setValue(params.q);
    }
  }

  ngOnInit() {
    this.store.select(getOnlineStatus).pipe(takeUntil(this.isAlive$)).subscribe((v: boolean) => this.isAppOnline = v);
    this.setDefaultOptions();
    this.toggleAllInSelection();

    this.appRepository.getFilters().pipe(takeUntil(this.isAlive$)).subscribe(v => {
      if (!!v) {
        this.feedFilters = v;

        if (v.rawFilterValues) {
          this.talkFilters.patchValue({...v.rawFilterValues.talkFilters});
          this.taskFilters.patchValue({...v.rawFilterValues.taskFilters});
          this.mailFilters.patchValue({...v.rawFilterValues.mailFilters});
          this.changeDetectorRef.markForCheck();
        }

       
        if (!!v.filtes?.selectedTags) {
          this.tags = v.filtes.selectedTags;
        }

        if (v.apps) {
          let includedApps = {};
          v.apps.forEach(app => {
            includedApps[app] = true;
          });
          this.includedApps = includedApps;
        }
        this.changeDetectorRef.markForCheck();
      }
    });

    this.tagCtrl.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe( v => {
        this.appRepository.getFilteredVNCDTags({name: v}).subscribe(
          res => {
           
            const tags = res.tags.map( v => ({name: v.name, color: v.color_hex}));
            const filteredTags = tags.filter( t => !this.tags.map(a => a.name).includes(t.name));
            this.filteredTags = [...filteredTags];
            this.changeDetectorRef.markForCheck();
          }
        );
      }
    );

    this.talkFilters.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      const initialValue = {
        insideAttachments: false,
        sender: [-1]
      };
     
      this.isTalkFiltersChange = !isEqual(res, initialValue);
    });

    this.mailFilters.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      const initialValue = {
        receivedFrom: [-1],
        sentTo: [-1],
        // attachmentType: [-1],
        status: [-1],
        folder: [-1],
      };
      this.isMailFiltersChange = !isEqual(res, initialValue);
    });

    this.taskFilters.valueChanges.pipe(takeUntil(this.isAlive$)).subscribe(res => {
      const initialValue = {
        assignedTo: [-1],
        // project: [-1],
        status: [-1],
        priority: [-1]
      };
      this.isTaskFiltersChange = !isEqual(res, initialValue);
    });

    setTimeout(() => {
      this.showAllAsDefault = false;
      this.changeDetectorRef.markForCheck();
    }, 1000);

  }

  setDefaultOptions() {
    this.showOnlyUnreadCtrl.setValue(false);
    this.tagCtrl.setValue("");
    this.tags = [];
    this.resetTalkFilters();
    this.resetTaskFilters();
    this.resetMailFilters();

    this.appRepository.getFederatedApps().pipe(filter(v => !!v && v.length > 0), take(1)).subscribe(apps => {
     
      apps.forEach(app => {
        this.includedApps[app.appKey] = true;
      });
    });
    this.showAllAsDefault = true;
    this.selected = {
      startDate: null,
      endDate: null
    };
    this.changeDetectorRef.markForCheck();
    this.search();
  }

  resetTalkFilters () {
    this.talkFilters.reset({
      insideAttachments: false,
      sender: [-1]
    });
    this.isTalkFiltersChange = false;
    this.changeDetectorRef.markForCheck();
  }

  resetTaskFilters () {
    this.taskFilters.reset({
      assignedTo: [-1],
      // project: [-1],
      status: [-1],
      priority: [-1]
    });
    this.isTaskFiltersChange = false;
    this.changeDetectorRef.markForCheck();
  }

  resetMailFilters () {
    this.mailFilters.reset({
      receivedFrom: [-1],
      sentTo: [-1],
      // attachmentType: [-1],
      status: [-1],
      folder: [-1],
    });
    this.isMailFiltersChange = false;
    this.changeDetectorRef.markForCheck();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    window.scrollTo(0, 0);
  }

  closeSidebar() {
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  ngAfterViewInit() {
  }

  toggleSearch(mode) {
    this.searchMode = mode;
    this.changeDetectorRef.markForCheck();
  }

  search() {
    // const filters = {
    //   ...this.feedFilters,
    //   talkFilters: this.talkFilters.getRawValue(),
    //   taskFilters: this.taskFilters.getRawValue(),
    //   mailFilters: this.mailFilters.getRawValue(),
    //   selectedTags: this.tags
    // };

    const rawFilterValues = {
      talkFilters: this.talkFilters.getRawValue(),
      taskFilters: this.taskFilters.getRawValue(),
      mailFilters: this.mailFilters.getRawValue(),
    };

    const filters = {
      talkFilters: this.transformFilters(this.talkFilters.getRawValue()),
      taskFilters: this.transformFilters(this.taskFilters.getRawValue()),
      mailFilters: this.transformFilters(this.mailFilters.getRawValue()),
      selectedTags: this.tags
    };

    const apps = Object.keys(this.includedApps).filter(app => this.includedApps[app]);
    this.appRepository.setDateRange(this.selected);
    this.appRepository.setSearchString(this.searchKeyword.value);
    this.appRepository.setFilters({apps, filters: filters, keyword: this.searchKeyword.value, rawFilterValues});
    this.appRepository.setUnreadOnly(this.showOnlyUnreadCtrl.value);
    if (this.searchKeyword.value) {
      this.router.navigate(["/search"], { queryParams: { q: this.searchKeyword.value } });
    } else {
      this.router.navigate(["/search"]);
    }
    this.broadcaster.broadcast("triggerSearch");
    
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  tagRemoved(tag: any): void {
    const index = [...this.tags].findIndex((v) => v.name === tag.name);
    if (index >= 0) {
      this.tags = this.tags.filter((a, i) => i !== index);
    }
    this.changeDetectorRef.markForCheck();
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.tagCtrl.setValue("");
    this.tagInput.nativeElement.value = "";
    if (!this.tags.find(v => v.name === value.name)) {
      this.tags.push(value);
    }
    this.changeDetectorRef.markForCheck();
  }


  clearSearch() {
    this.searchKeyword.reset();
    this.changeDetectorRef.markForCheck();
  }

  clearField(form: string, field: string) {
    switch (form) {
      case "talk" : {
        if (field === "sender") {
          this.talkFilters.controls[field].reset([-1]);
        } else {
          this.talkFilters.controls[field].reset();
        }
        this.changeDetectorRef.markForCheck();
        return;
      }
      case "task" : {
        this.taskFilters.controls[field].reset([-1]);
        this.changeDetectorRef.markForCheck();
        return;
      }
      case "mail" : {
        this.mailFilters.controls[field].reset([-1]);
        this.changeDetectorRef.markForCheck();
        return;
      }
      default: {
        return;
      }
    }
  }

  toggleAllInSelection() {
    this.talkFilters.controls["sender"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.talkAllSendersSelected)) {
          this.talkFilters.controls["sender"].setValue([-1]);
          this.talkAllSendersSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.talkAllSendersSelected = false;
          this.talkFilters.controls["sender"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.taskFilters.controls["assignedTo"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.taskAllAssignedSelected)) {
          this.taskFilters.controls["assignedTo"].setValue([-1]);
          this.taskAllAssignedSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.taskAllAssignedSelected = false;
          this.taskFilters.controls["assignedTo"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    // this.taskFilters.controls["project"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
    //   (p: any, q: any) => p.toString() === q.toString()
    // )).subscribe(
    //   res => {
    //     if (res.toString() === "-1") {
    //       return;
    //     } else if (!res.length || (res.includes(-1) && !this.taskAllProjectsSelected)) {
    //       this.taskFilters.controls["project"].setValue([-1]);
    //       this.taskAllProjectsSelected = true;
    //     } else if (res.includes(-1)) {
    //       // res.splice(0, 1);
    //       this.taskAllProjectsSelected = false;
    //       this.taskFilters.controls["project"].setValue(res.filter((a, index) => index !== 0));
    //     }
    //   }
    // );

    this.taskFilters.controls["status"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.taskAllStatusSelected)) {
          this.taskFilters.controls["status"].setValue([-1]);
          this.taskAllStatusSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.taskAllStatusSelected = false;
          this.taskFilters.controls["status"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.taskFilters.controls["priority"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.taskAllPrioritySelected)) {
          this.taskFilters.controls["priority"].setValue([-1]);
          this.taskAllPrioritySelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.taskAllPrioritySelected = false;
          this.taskFilters.controls["priority"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.mailFilters.controls["receivedFrom"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.mailAllReceivedFromSelected)) {
          this.mailFilters.controls["receivedFrom"].setValue([-1]);
          this.mailAllReceivedFromSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.mailAllReceivedFromSelected = false;
          this.mailFilters.controls["receivedFrom"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.mailFilters.controls["sentTo"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.mailAllSentToSelected)) {
          this.mailFilters.controls["sentTo"].setValue([-1]);
          this.mailAllSentToSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.mailAllSentToSelected = false;
          this.mailFilters.controls["sentTo"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    // this.mailFilters.controls["attachmentType"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
    //   (p: any, q: any) => p.toString() === q.toString()
    // )).subscribe(
    //   res => {
    //     if (res.toString() === "-1") {
    //       return;
    //     } else if (!res.length || (res.includes(-1) && !this.mailAllAttachmentSelected)) {
    //       this.mailFilters.controls["attachmentType"].setValue([-1]);
    //       this.mailAllAttachmentSelected = true;
    //     } else if (res.includes(-1)) {
    //       // res.splice(0, 1);
    //       this.mailAllAttachmentSelected = false;
    //       this.mailFilters.controls["attachmentType"].setValue(res.filter((a, index) => index !== 0));
    //     }
    //   }
    // );

    this.mailFilters.controls["status"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.mailAllStatusSelected)) {
          this.mailFilters.controls["status"].setValue([-1]);
          this.mailAllStatusSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.mailAllStatusSelected = false;
          this.mailFilters.controls["status"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );

    this.mailFilters.controls["folder"].valueChanges.pipe( takeUntil(this.isAlive$), distinctUntilChanged(
      (p: any, q: any) => p.toString() === q.toString()
    )).subscribe(
      res => {
        if (res.toString() === "-1") {
          return;
        } else if (!res.length || (res.includes(-1) && !this.mailAllFoldersSelected)) {
          this.mailFilters.controls["folder"].setValue([-1]);
          this.mailAllFoldersSelected = true;
        } else if (res.includes(-1)) {
          // res.splice(0, 1);
          this.mailAllFoldersSelected = false;
          this.mailFilters.controls["folder"].setValue(res.filter((a, index) => index !== 0));
        }
      }
    );
  }

  getNames(ids: (string | number)[], data: any[]) {
    if (!ids.length || ids.toString() === "-1") {
      return null;
    }
    const selectedData = [...data].filter(v => ids.includes(v.id));
    if (!selectedData.length) {
      return null;
    }
    return selectedData.map( v => v.name);
  }

  isTalkFiltersSelected() {
    const filters = this.talkFilters.getRawValue();
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key].toString() === "-1") {
        delete filters[key];
      }
    });
    return !!Object.keys(filters).length;
  }

  isTaskFiltersSelected() {
    const filters = this.taskFilters.getRawValue();
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key].toString() === "-1") {
        delete filters[key];
      }
    });
    return !!Object.keys(filters).length;
  }

  isMailFiltersSelected() {
    const filters = this.mailFilters.getRawValue();
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key].toString() === "-1") {
        delete filters[key];
      }
    });
    return !!Object.keys(filters).length;
  }

  transformFilters(filters) {
    Object.keys(filters).forEach(key => {
      if (!filters[key] || filters[key].toString() === "-1") {
        delete filters[key];
      } else if (Array.isArray(filters[key])) {
        filters[key] = (filters[key] as AdvSearchFilter[]).map(v => v.value);
      }
    });
    return filters;
  }

  datesUpdated(range) {
  
    this.selected = {...range};
  }

  resetDateFilter() {
    this.selected = {
      startDate: moment().subtract(30, "days").set({hours: 0, minutes: 0}),
      endDate: moment()
    };
  }
}
