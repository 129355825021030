import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { BehaviorSubject } from "rxjs";
import { AppRepository } from "../src/app/repositories/app.repository";

@Injectable({
  providedIn: "root"
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  publicKey = "BH-oozNWyfvqyIJR2c4YI0i9C5O7q00lJsLk_pqV7QRSd9pXUekv6UZRguSr7bbojb1pD8_U6rPQOBSx1dcu-v0";

  constructor(
    private appRepository: AppRepository,
    private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {

        this.appRepository.saveFCMToken(token);
      },
      (err) => {
        console.error("[FIREBASE] Unable to get permission to notify.", err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {

        this.currentMessage.next(payload);
      });
  }

}

