
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<article *ngIf="document" class="doc-preview condensed-view" [class.is-read] = "!document.unread">
  <header class = "doc-header">
    <div class = "user-avatar">
      <vp-avatar [type] = "'article-avatar'" [user] = "{'email': document?.from }">
      </vp-avatar>
      <img *ngIf="document.type === 'talk'" class="app-logo-mini" src="assets/images/vnctalk.svg" />
      <img *ngIf="document.type === 'issue'" class="app-logo-mini" src="assets/images/vnctask.svg" />
      <img *ngIf="document.type === 'mail'" class="app-logo-mini" src="assets/images/vncmail.svg" />
    </div>

    <div class="doc-details">
      <div class="doc-details__heading">
        <span class = "doc-title">{{ documentTitle ? documentTitle : authorName }}</span>
        <div class = "doc-star">
          <button *ngIf = "document.metadata && !document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();addStar(document)">
            <mat-icon class = "unstarred-icon">star_border</mat-icon>
          </button>
          <button *ngIf = "document.metadata && document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();removeStar(document)">
            <mat-icon class = "starred-icon">star</mat-icon>
          </button>
        </div>
      </div>
      <div class="user-info">
        <span *ngIf="documentTitle" class = "doc-title">{{ authorName }}</span>
        <span *ngIf="documentTitle" class="divider-dot">.</span>
        <div class = "doc-time">
          {{ document?.createdDt | vpDisplayTime : "MM/DD hh:mm" }}
        </div>
      </div>
    </div>

<!--    <img *ngIf="document.type === 'talk'" class="app-logo" src="assets/images/vnctalk.svg" />-->
<!--    <img *ngIf="document.type === 'task'" class="app-logo" src="assets/images/vnctask.svg" />-->
<!--    <img *ngIf="document.type === 'mail'" class="app-logo" src="assets/images/vncmail.svg" />-->

<!--    <span class="doc-title">{{ documentTitle || authorName }}</span>-->

<!--    <div class="doc-metadata">-->
<!--      <div class="user-avatar">-->
<!--        <vp-avatar [type]="'article-avatar'" [user]="{'email': document?.from }">-->
<!--        </vp-avatar>-->
<!--      </div>-->
<!--      <span class="doc-time">-->
<!--          {{ document?.createdDt | vpDisplayTime : "DD.MM.YYYY, hh:mm" }}-->
<!--      </span>-->
<!--    </div>-->
    <div class="doc-actions">
      <button *ngIf="document.unread && document.type === 'talk'" mat-button (click)="markAsRead()">
        <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-marked-circle-outline"></mat-icon>
      </button>
      <button *ngIf="!document.unread && document.type === 'talk'" mat-button (click)="markAsUnRead()">
        <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-marked-circle"></mat-icon>
      </button>
<!--      <button mat-button (click)="underDevelopment()">-->
<!--        <mat-icon fontSet="mdi" fontIcon="mdi-comment-outline"></mat-icon>-->
<!--      </button>-->
<!--      <button mat-button *ngIf="document.type !== 'mail'">-->
<!--        <mat-icon *ngIf="document.type !== 'mail'" (click)="underDevelopment()" fontSet="mdi" fontIcon="mdi-paperclip"></mat-icon>-->
<!--      </button>-->
      <button mat-button *ngIf="document.type === 'mail' && document.mailAttachments.length > 0"
              [matMenuTriggerFor]="mailAttachmentsMenu">
        <mat-icon fontSet="mdi" fontIcon="mdi-paperclip"></mat-icon>
        <span class="mail-attachment-count">{{document.mailAttachments.length}}</span>
        <mat-menu #mailAttachmentsMenu="matMenu" yPosition="below" class="attachment-items-menu">
          <div *ngIf="document.mailAttachments.length > 0">
              <div mat-menu-item *ngFor="let attachment of document.mailAttachments" (click)="filePreview(attachment)">
                  <div class="item-content">
                      <mat-icon *ngIf="attachment.fileType =='image'" >insert_photo</mat-icon>
                      <mat-icon *ngIf="attachment.fileType == 'other'" >description</mat-icon>
                      <mat-icon *ngIf="attachment.fileType == 'pdf'" fontSet="mdi" fontIcon="mdi-file-pdf-box"></mat-icon>
                      <span class="file-name">{{attachment.name}}</span>
                  </div>
              </div>
              <div mat-menu-item class="download-all-option" (click)="downloadAllAttachments(document.mailAttachments)">
                <div class="item-content">
                    <mat-icon>get_app</mat-icon>
                    <span class="download-all">{{'DOWNLOAD_ALL_ATTACHMENT_LABEL' | translate}}</span>
                </div>
            </div>
          </div>
        </mat-menu>
      </button>
<!--      <button mat-button (click)="underDevelopment()">-->
<!--        <mat-icon fontSet="mdi" fontIcon="mdi-tag"></mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf="document.metadata && !document.metadata.isStarred" mat-button-->
<!--              (click)="$event.stopPropagation();addStar(document)">-->
<!--        <mat-icon class="unstarred-icon">star_border</mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf="document.metadata && document.metadata.isStarred" mat-button-->
<!--              (click)="$event.stopPropagation();removeStar(document)">-->
<!--        <mat-icon class="starred-icon">star</mat-icon>-->
<!--      </button>-->
      <button mat-button (click)="underDevelopment()">
        <mat-icon fontSet="mdi" fontIcon="mdi-information-variant"></mat-icon>
      </button>
      <button mat-button class="doc-kebab-menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </header>

  <header class = "doc-header--mobile">
    <div class="doc-metadata">
      <div class="user-avatar">
        <vp-avatar [type]="'article-avatar'" [user]="{'email': document?.from }">
        </vp-avatar>
      </div>

      <div class="doc-metadata__details">
        <div class="name-block">
          <span class="user-name">{{ authorName }}</span>
          <button *ngIf="document.unread" mat-button (click)="markAsRead()">
            <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-marked-circle-outline"></mat-icon>
          </button>
          <button *ngIf="!document.unread" mat-button (click)="markAsUnRead()">
            <mat-icon fontSet="mdi" fontIcon="mdi-checkbox-marked-circle"></mat-icon>
          </button>
        </div>

        <div class="meta-data-block">
          <span class="doc-time">
           {{ document?.createdDt | vpDisplayTime : "DD MMM,YYYY, hh:mm" }}
          </span>

          <span class="comments">
            <mat-icon fontSet="mdi" fontIcon="mdi-comment-outline"></mat-icon>
            {{commentsCount}}
          </span>

          <span class="tags">
            <mat-icon>local_offer</mat-icon>
            {{tagsCount}}
          </span>

          <span class="attachments">
            <mat-icon fontSet="mdi" fontIcon="mdi-paperclip"></mat-icon>
            {{attachmentsCount}}
          </span>
        </div>
      </div>

      <div class="info-block">
        <button mat-icon-button (click)="$event.stopPropagation();underDevelopment()">
          <mat-icon fontSet="mdi" fontIcon="mdi-information-variant"></mat-icon>
        </button>
      </div>
    </div>

    <div class="doc-metadata-2">
      <img *ngIf="document.type === 'talk'" class="app-logo" src="assets/images/vnctalk.svg" />
      <img *ngIf="document.type === 'issue'" class="app-logo" src="assets/images/vnctask.svg" />
      <img *ngIf="document.type === 'mail'" class="app-logo" src="assets/images/vncmail.svg" />
      <span class="doc-title">{{ documentTitle || authorName }}</span>

      <button *ngIf="document.metadata && !document.metadata.isStarred" mat-button
              (click)="$event.stopPropagation();addStar(document)">
        <mat-icon class="unstarred-icon">star_border</mat-icon>
      </button>
      <button *ngIf="document.metadata && document.metadata.isStarred" mat-button
              (click)="$event.stopPropagation();removeStar(document)">
        <mat-icon class="starred-icon">star</mat-icon>
      </button>

      <button mat-button class="doc-kebab-menu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </header>

  <div class="doc-body no-background" (click)="openQuickPreview()">
    <div class="doc-content show-less-content" [innerHTML]="renderContent(document.shortContent) | vpSafeHtml"></div>
  </div>
</article>
