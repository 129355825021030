/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Directive, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Output, Renderer2 } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Observable, Subject } from "rxjs";
import { requestIdleCallbackObservable } from "./request-idle-callback";
import { switchMapTo, takeUntil } from "rxjs/operators";

const clickElements = new Set<HTMLElement>();
  const eventName: string =
    typeof window !== "undefined" && typeof window["Hammer"] !== "undefined"
      ? "tap"
      : "click";

  @Directive({
    selector: "[vpCalClick]"
  })

  export class ClickDirective implements OnInit, OnDestroy {
    @Input() clickListenerDisabled = false;
    @Output("vpCalClick") click = new EventEmitter<MouseEvent>(); // tslint:disable-line

    private destroy$ = new Subject();

    constructor(
      private renderer: Renderer2,
      private elm: ElementRef<HTMLElement>,
      @Inject(DOCUMENT) private document,
      private zone: NgZone
    ) {}

    ngOnInit(): void {
      if (!this.clickListenerDisabled) {
        this.renderer.setAttribute(
          this.elm.nativeElement,
          "data-calendar-clickable",
          "true"
        );
        clickElements.add(this.elm.nativeElement);

        // issue #942 - lazily initialise all click handlers after initial render as hammerjs is slow
        requestIdleCallbackObservable()
          .pipe(
            switchMapTo(this.listen()),
            takeUntil(this.destroy$)
          )
          .subscribe(event => {
            // prevent child click events from firing on parent elements that also have click events
            let nearestClickableParent = event.target as HTMLElement;
            while (
              !clickElements.has(nearestClickableParent) &&
              nearestClickableParent !== this.document.body
            ) {
              nearestClickableParent = nearestClickableParent.parentElement;
            }
            const isThisClickableElement =
              this.elm.nativeElement === nearestClickableParent;
            if (isThisClickableElement) {
              this.zone.run(() => {
                this.click.next(event);
              });
            }
          });
      }
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      clickElements.delete(this.elm.nativeElement);
    }

    private listen() {
      return new Observable<MouseEvent>(observer => {
        return this.renderer.listen(this.elm.nativeElement, eventName, event => {
          observer.next(event);
        });
      });
    }
  }
