/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { Conversation, ConversationConfig } from "../common/models/conversation.model";
import { MessageActionTypes } from "../actions/message";
import { ConversationActionTypes } from "../actions/conversation";
import { AppActionTypes } from "../actions/app";
import { Message } from "../common/models/message.model";
import { Action } from "../actions";
// import * as _ from "lodash";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import find from "lodash/find";
import { CommonUtils } from "../common/utils/common-util";

export interface ConversationState extends EntityState<Conversation> {
  isLoading: boolean;
  isLoaded: boolean;

  configLoaded: { [conversationTarget: string]: any[] };

  blockedBareIds: string[];

  // Target of Conversations which have show details enabled
  detailsVisibleBareIds: string[];

  // Conversations which have already been opened for the first time
  activatedConversations: string[];

  // Selected Conversation for which messages will be shown.
  selectConversationId: string | null;
  whiteboard: any;

  participants: { [conversationTarget: string]: any[] };
  members: { [conversationTarget: string]: string[] };
  owner: { [conversationTarget: string]: string };
  favorites: { [conversationTarget: string]: string[] };
  roomIds: { [conversationTarget: string]: any };
  lastActivity: { [conversationTarget: string]: string };
  lastText: { [conversationTarget: string]: string };
  configs: { [conversationTarget: string]: ConversationConfig };
  fileInProgress: { [messageId: string]: { loaded: number, total: number } };
  selectedMessageIds: string[];
  activeConversation: Conversation;
}

export const conversationAdapter: EntityAdapter<Conversation> = createEntityAdapter<Conversation>({
  selectId: (conversation: Conversation) => conversation.Target,
  sortComparer: sortByTimestamp
});

export function sortByTimestamp(conv1: Conversation, conv2: Conversation): number {
  return conv2.Timestamp - conv1.Timestamp;
}

export const initialState: ConversationState = conversationAdapter.getInitialState({
  isLoading: false,
  isLoaded: false,

  configLoaded: {},

  blockedBareIds: [],
  mutedBareIds: [],
  soundOffBareIds: [],

  detailsVisibleBareIds: [],

  activatedConversations: [],

  selectConversationId: null,
  whiteboard: {},
  lastText: {},
  lastActivity: {},
  participants: {},
  members: {},
  owner: {},
  favorites: {},
  roomIds: {},
  background: {},
  configs: {},
  selectedMessageIds: [],
  fileInProgress: {},
  notificationSettings: {},
  activeConversation: null
});

export function conversationReducer(state: ConversationState = initialState, action: Action): ConversationState {
  switch (action.type) {

    case MessageActionTypes.MESSAGE_ADD: {
      const payload = action.payload;
      const conversationTarget = payload.conversationTarget;
      const message = payload.message as Message;

      if (message.type === "CHAT.JOIN" || message.type === "CHAT.LEAVE" || message.type === "CHAT.KICK" || message.mucInvite) {
        return state;
      }

      const conversation = state.entities[conversationTarget];

      if (CommonUtils.isNullOrUndefined(conversation)) {
        return state;
      }

      const skipUpdate = conversation.Timestamp > message.timestamp;

      if (skipUpdate) {
        return state;
      }

      const changes = {
        Timestamp: message.timestamp,
        content: message.body,
        archived: false
      };

      if (!CommonUtils.isNullOrUndefined(action.payload.incoming)) {
        changes["incoming"] = action.payload.incoming;
      }

      return {
        ...conversationAdapter.updateOne({
          id: conversationTarget, changes: changes
        }, state),
      };
    }


    case MessageActionTypes.MULTI_CONVERSATION_MESSAGE_ADD: {
      const data = action.payload;

      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const message = item.message as Message;
        const conversation = localState.entities[conversationTarget];

        if (!(message.type === "CHAT.JOIN" || message.type === "CHAT.LEAVE" || message.type === "CHAT.KICK" || message.mucInvite)
          && conversation
          && message.timestamp > conversation.Timestamp
          && message.body) {

          const changes = {
            Timestamp: message.timestamp,
            content: message.body,
            archived: false
          };
          if (!message.vncTalkConference) {
            changes["conferenceType"] = "chat";
          } else {
            changes["conferenceType"] = message.vncTalkConference.conferenceType;
          }

          if (!CommonUtils.isNullOrUndefined(item.incoming)) {
            changes["incoming"] = item.incoming;
          }

          localState = {
            ...conversationAdapter.updateOne({
              id: conversationTarget, changes: changes
            }, localState),
          };
        }

      });

      return { ...localState };
    }

    case MessageActionTypes.MESSAGE_BULK_APPEND_MULTI_CONVERSATION: {
      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const messages = item.messages || [];
        const conversation = localState.entities[conversationTarget];


        if (messages.length > 0) {
          messages.sort((msg1, msg2) => msg1.timestamp - msg2.timestamp);
          const lastMessage = messages[messages.length - 1];

          if (!(lastMessage.type === "CHAT.JOIN" || lastMessage.type === "CHAT.LEAVE" || lastMessage.type === "CHAT.KICK"
            || lastMessage.mucInvite) && conversation && lastMessage.timestamp > conversation.Timestamp) {

            localState = {
              ...conversationAdapter.updateOne({
                id: conversationTarget, changes: {
                  Timestamp: lastMessage.timestamp,
                  content: lastMessage.body,
                  archived: false
                }
              }, localState),
            };
          }
        }

      });
      return { ...localState };
    }



    case MessageActionTypes.SELECT_MESSAGE: {
      if (CommonUtils.isNullOrUndefined(action.payload)) {
        return state;
      }
      return {
        ...state,
        selectedMessageIds: uniq([
          ...state.selectedMessageIds,
          action.payload
        ])
      };
    }

    case MessageActionTypes.UNSELECT_MESSAGE: {
      const messageId = action.payload;
      let selectedMessageIds = state.selectedMessageIds.filter(id => messageId !== id);
      return {
        ...state,
        selectedMessageIds: selectedMessageIds
      };
    }

    case MessageActionTypes.RESET_MESSAGES: {
      return {
        ...state,
        selectedMessageIds: []
      };
    }

    case ConversationActionTypes.CONVERSATION_LOAD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }

    case ConversationActionTypes.CONVERSATION_LOAD_SUCCESS: {

      const newState = {
        ...state,
        isLoaded: true
      };

      return conversationAdapter.addMany(action.payload, newState);
    }


    case ConversationActionTypes.CONVERSATION_NEXT_LOAD_SUCCESS: {
      const newState = conversationAdapter.addMany(action.payload.conversations, {
        ...state,
        isLoaded: true
      });
      const changes = action.payload.conversations.map(conv => {
        return { id: conv.Target, changes: conv };
      });
      return conversationAdapter.updateMany(changes, newState);
    }

    case ConversationActionTypes.CONVERSATION_CREATE: {
      return conversationAdapter.addOne(action.payload, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_SUBJECT: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { "groupChatTitle": action.payload.subject }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_TITLE: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: { "broadcast_title": action.payload.title }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_AUDIENCE: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: { "audience": action.payload.audience }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_BROADCAST_DATA: {
      return conversationAdapter.updateOne({
        id: action.payload.conversationTarget,
        changes: action.payload.changes
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_INACTIVE: {
      return conversationAdapter.updateOne({
        id: action.payload.target,
        changes: { "state": "inactive" }
      }, state);
    }


    case MessageActionTypes.MESSAGE_DELETED_STATUS_UPDATE: {
      return conversationAdapter.updateOne({
        id: action.payload.convTarget,
        changes: { content: "" }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_INITIAL_ACTIVE: {
      return {
        ...state,
        activatedConversations: uniq([
          ...state.activatedConversations,
          action.payload
        ])
      };
    }

    case ConversationActionTypes.CONVERSATION_RESET_ALL_ACTIVATED: {
      return {
        ...state,
        activatedConversations: []
      };
    }

    case ConversationActionTypes.CONVERSATION_SELECT: {
      return {
        ...state,
        selectConversationId: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_LAST_ACTIVITY: {
      return {
        ...state,
        lastActivity: {
          ...state.lastActivity,
          [action.payload.target]: action.payload.seconds
        }
      };
    }



    // case ConversationActionTypes.START_CONFERENCE: {
    //   return {
    //     ...state,
    //     activeConference: action.payload
    //   };
    // }

    // case ConversationActionTypes.SET_JITSI_ROOM: {
    //   let activeConference = state.activeConference;
    //   activeConference.jitsiRoom = action.payload.jitsiRoom;
    //   return {
    //     ...state,
    //     activeConference
    //   };
    // }

    // case ConversationActionTypes.START_WHITEBOARD: {
    //   return {
    //     ...state,
    //     whiteboard: action.payload
    //   };
    // }

    case ConversationActionTypes.CONVERSATION_DE_SELECT: {
      return {
        ...state,
        selectConversationId: null
      };
    }

    case ConversationActionTypes.CONVERSATION_DELETE: {
      return conversationAdapter.removeOne(action.payload, state);
    }


    case ConversationActionTypes.CONVERSATION_MULTIPLE_DELETE: {
      return conversationAdapter.removeMany(action.payload, state);
    }


    case ConversationActionTypes.CONVERSATION_UPDATE_NOTIFICATION_SETTING: {
      const convTarget = action.payload.conversationTarget as string;
      const type = action.payload.type as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          mute_notification: type
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_SOUND_SETTING: {
      const convTarget = action.payload.conversationTarget as string;
      const type = action.payload.type as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          mute_sound: type
        }
      }, state);
    }


    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_INDEX: {
      return {
        ...state,
        blockedBareIds: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_ADD: {
      return {
        ...state,
        blockedBareIds: [
          ...state.blockedBareIds,
          action.payload
        ]
      };
    }

    case ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_ADD: {
      return {
        ...state,
        blockedBareIds: [
          ...state.blockedBareIds,
          ...action.payload
        ]
      };
    }

    case ConversationActionTypes.CONVERSATIONS_BLOCK_LIST_REMOVE: {

      let newBlockedBareIds = state.blockedBareIds;

      if (newBlockedBareIds) {
        newBlockedBareIds = newBlockedBareIds.filter(item => action.payload.indexOf(item) === -1);
      }

      return {
        ...state,
        blockedBareIds: newBlockedBareIds
      };
    }

    case ConversationActionTypes.CONVERSATION_BLOCK_LIST_REMOVE: {

      let newBlockedBareIds = state.blockedBareIds;

      if (newBlockedBareIds) {
        newBlockedBareIds = newBlockedBareIds.filter(item => item !== action.payload);
      }

      return {
        ...state,
        blockedBareIds: newBlockedBareIds
      };
    }

    case ConversationActionTypes.CONVERSATION_SHOW_DETAILS: {
      return {
        ...state,
        detailsVisibleBareIds: [
          ...state.detailsVisibleBareIds,
          action.payload
        ]
      };
    }

    case ConversationActionTypes.CONVERSATION_HIDE_DETAILS: {
      return {
        ...state,
        detailsVisibleBareIds: filter(state.detailsVisibleBareIds, action.payload)
      };
    }

    case ConversationActionTypes.CONVERSATION_APPEND_PARTICIPANTS: {
      const conversationTarget = action.payload.conversationTarget;
      const participants = action.payload.participants;

      let oldParticipants = [];

      if (state.participants[conversationTarget]) {
        oldParticipants = state.participants[conversationTarget];
      }

      return {
        ...state,
        participants: {
          ...state.participants,
          [conversationTarget]: uniqBy([
            ...oldParticipants,
            ...participants
          ], "full")
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_BULK_APPEND_PARTICIPANTS: {

      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const participants = item.participants;

        let oldParticipants = [];

        if (localState.participants[conversationTarget]) {
          oldParticipants = localState.participants[conversationTarget];
        }

        localState = {
          ...localState,
          participants: {
            ...localState.participants,
            [conversationTarget]: uniqBy([
              ...oldParticipants,
              ...participants
            ], "full")
          }
        };

      });

      return localState;

    }

    case ConversationActionTypes.CONVERSATION_UPDATE_PARTICIPANTS: {
      const conversationTarget = action.payload.conversationTarget;
      const participants = action.payload.participants;

      return {
        ...state,
        participants: {
          ...state.participants,
          [conversationTarget]: participants
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_PARTICIPANT_ROLE: {
      const conversationTarget = action.payload.conversationTarget;
      const bare = action.payload.bare;
      const role = action.payload.role;
      const participants = state.participants;
      let existingParticipants = participants[conversationTarget];
      let existingParticipant = find(existingParticipants, { bare: bare });
      if (existingParticipant) {
        existingParticipant.affiliation = role;
        existingParticipants = [...existingParticipants, ...[existingParticipant]];
      }
      return {
        ...state,
        participants: {
          ...state.participants,
          [conversationTarget]: existingParticipants
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_MEMBERS: {
      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const newMembers = action.payload.members;
      const members = [...exitingMembers, ...newMembers];
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: uniq(members)
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_OWNER: {
      const conversationTarget = action.payload.conversationTarget;
      const newOwner = action.payload.owner;
      return {
        ...state,
        owner: {
          ...state.owner,
          [conversationTarget]: newOwner
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_JITSI_ROOM: {
      const conversationTarget = action.payload.conversationTarget;
      return {
        ...state,
        roomIds: {
          ...state.roomIds,
          [conversationTarget]: action.payload.option
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_ROOM_IDS: {
      return {
        ...state,
        roomIds: action.payload
      };
    }

    case ConversationActionTypes.CONVERSATION_UPDATE_UPLOADING_FILE: {
      const messageId = action.payload.messageId;
      const progress = action.payload.progress;
      return {
        ...state,
        fileInProgress: {
          ...state.fileInProgress,
          [messageId]: progress
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_FILE_UPLOADED: {
      const messageId = action.payload.messageId;
      let fileInProgress = state.fileInProgress;
      delete fileInProgress[messageId];
      return {
        ...state,
        fileInProgress: fileInProgress
      };
    }


    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_MEMBERS: {

      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const exitingMembers = localState.members[conversationTarget] || [];
        const newMembers = item.members;
        const members = [...exitingMembers, ...newMembers];
        localState = {
          ...localState,
          members: {
            ...localState.members,
            [conversationTarget]: uniq(members)
          }
        };
      });

      return localState;

    }

    case ConversationActionTypes.MULTI_CONVERSATION_UPDATE_OWNER: {

      const data = action.payload;
      let localState = { ...state };

      data.forEach(item => {
        const conversationTarget = item.conversationTarget;
        const newOwner = item.owner;
        localState = {
          ...localState,
          owner: {
            ...localState.owner,
            [conversationTarget]: newOwner
          }
        };
      });

      return localState;

    }

    case ConversationActionTypes.CONVERSATION_REMOVE_MEMBER: {
      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const removedMember = action.payload.member;
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: uniq(exitingMembers.filter(m => m !== removedMember))
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_REMOVE_MEMBERS: {
      const conversationTarget = action.payload.conversationTarget;
      const exitingMembers = state.members[conversationTarget] || [];
      const members = action.payload.members;
      return {
        ...state,
        members: {
          ...state.members,
          [conversationTarget]: uniq(exitingMembers.filter(m => members.indexOf(m) === -1))
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_TEXT: {
      const conversationTarget = action.payload.conversationTarget;
      const text = action.payload.text;

      return {
        ...state,
        lastText: {
          ...state.lastText,
          [conversationTarget]: text
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_REMOVE_PARTICIPANT: {
      const conversationTarget = action.payload.conversationTarget;
      const bare = action.payload.bare;
      const jidFull = action.payload.jidFull;
      const existingParticipants = state.participants[conversationTarget];
      let participants = [];

      if (!existingParticipants) {
        return state;
      }

      if (bare) {
        participants = existingParticipants.filter(item => item.bare !== bare);
      } else if (jidFull) {
        participants = existingParticipants.filter(item => item.full !== jidFull);
      } else {
        console.error("Either jidFull or bare should be specified fore removing participant");
        return state;
      }

      return {
        ...state,
        participants: {
          ...state.participants,
          [conversationTarget]: participants
        }
      };
    }

    case ConversationActionTypes.CONVERSATION_SET_CONFIG: {
      const conversationTarget = action.payload.conversationTarget;
      const config = action.payload.config;

      return {
        ...state,
        configs: {
          ...state.participants,
          [conversationTarget]: config
        }
      };
    }

    case AppActionTypes.RESTORE_SAVED_STATE: {
      const savedState = action.payload.conversationState;
      return savedState ? { ...state, ...savedState } : state;
    }

    case ConversationActionTypes.ARCHIVE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const timestamp = action.payload.timestamp as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          archived: true,
          Timestamp: timestamp
        }
      }, state);
    }

    case ConversationActionTypes.UPDATE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const changes = action.payload.changes;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: changes
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_RESET_UNREAD: {
      const conversationTarget = action.payload;

      return conversationAdapter.updateOne({
        id: conversationTarget,
        changes: {
          unreadIds: [],
          unread_mentions: []
        }
      }, state);
    }

    case ConversationActionTypes.UNARCHIVE_CONVERSATION: {
      const convTarget = action.payload.target as string;
      const timestamp = action.payload.timestamp as number;

      return conversationAdapter.updateOne({
        id: convTarget,
        changes: {
          archived: false,
          Timestamp: timestamp
        }
      }, state);
    }

    case ConversationActionTypes.CONVERSATION_LAST_ACTIVITY: {
      const lastActivity = state.lastActivity;
      return {
        ...state,
        lastActivity: { ...lastActivity, ...action.payload }
      };
    }

    case ConversationActionTypes.CONVERSATION_LAST_TEXT: {
      return {
        ...state,
        lastText: action.payload
      };
    }

    case ConversationActionTypes.SET_ACTIVE_CONVERSATION: {
      return {
        ...state,
        activeConversation: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const _getIsLoading = (state: ConversationState) => state.isLoading;
export const _getIsLoaded = (state: ConversationState) => state.isLoaded;

export const _getSelectedConversationId = (state: ConversationState) => state.selectConversationId;

export const _getIsActivatedConversation = (state: ConversationState, conversationTarget: string) => {
  return state.activatedConversations.indexOf(conversationTarget) !== -1;
};

export const _getSelectedMessageIds = (state: ConversationState) => state.selectedMessageIds || [];
export const _getBlockedBareIds = (state: ConversationState) => state.blockedBareIds;
export const _getIsConversationBlocked = (state: ConversationState, bareId: string) => state.blockedBareIds.indexOf(bareId) !== -1;

export const _getIsConversationDetailsVisible =
(state: ConversationState, bareId: string) => state.detailsVisibleBareIds.indexOf(bareId) !== -1;

export const _getConversationMembers = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.members[conversationTarget] ? state.members[conversationTarget] : [];
};

export const _getConversationOwner = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.owner[conversationTarget] ? state.owner[conversationTarget] : null;
};

export const _getConversationText = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.lastText[conversationTarget] ? state.lastText[conversationTarget] : "";
};

export const _getConversationConfig = (state: ConversationState, conversationTarget: string) => state.configs[conversationTarget];

export const _getConversationConfigLoaded =
(state: ConversationState, conversationTarget: string) => state.configLoaded[conversationTarget];
export const _getFileInProgressByMessageId = (state: ConversationState, messageId: string) => state.fileInProgress[messageId];
export const _getLastActivityByTarget = (state: ConversationState, target: string) => state.lastActivity[target];

export const _getLastActivity = (state: ConversationState) => state.lastActivity;
export const _getLastText = (state: ConversationState) => state.lastText;
export const _getConversationForParticipants = (state: ConversationState) => state.activeConversation;

export const _getConversationRoomIds = (state: ConversationState) => state.roomIds;

export const _getConversationRoomId = (state: ConversationState, conversationTarget: string) => {
  return conversationTarget && state.roomIds[conversationTarget];
};
