
<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="start-chat-dialog">
    <div class="commander__dialog__header space-between">
        <div class="header_left">
            <button *ngIf="appRepository.isMobileScreen" mat-button (click)="closeDialog()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
        <div class="header_title disable-select">
            {{ 'START_CHAT' | translate }}
        </div>
        <div class="header_right close-dialog">
            <a *ngIf="appRepository.isMobileScreen" [href]="appRepository.talkURL" target="_blank" class="open-app open-new-window">
                <div class="vnctalk app-info"><img src="assets/images/vnctalk.svg" />
                </div>
            </a>
            <button *ngIf="!appRepository.isMobileScreen" mat-button (click)="closeDialog()">
                <mat-icon class="disable-select">close</mat-icon>
            </button>
        </div>
    </div>
    <div class="commander__dialog__body">
        <div class="search-form">
            <input type="text" name="search" placeholder="{{ 'SEARCH_USERS' | translate }}"
                (keyup.enter)="handleEnterKeyPress()" [formControl]="searchControl" id="searchText">
                <mat-icon class="search-user-icon">search</mat-icon>
        </div>
        <div class="contacts-list is-singlechat">
            <div class="contact-item" *ngFor="let contact of filteredRecipients" (click)="startConversation(contact.target)">
                <div class="contact-avatar">
                    <vp-avatar [user]="{email: contact.target}" [type]="'contact-item-avatar'"></vp-avatar>
                </div>
                <div class="contact-fullname" title="{{ contact.target }}">
                    {{ contact.title }}
                </div>
            </div>
        </div>
    </div>
    <div class="commander__dialog__footer">
        <a [href]="appRepository.talkURL" target="_blank" class="open-app">
            <div class="vnctalk app-info"><img src="assets/images/vnctalk.svg" />
            </div>
            {{ 'OPEN_IN' | translate : { appName: 'VNCtalk'} }}
        </a>
    </div>
</div>