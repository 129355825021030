<!--
  ~ VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<article *ngIf = "document" class = "doc-preview" [class.is-read] = "!document.unread" (click) = "quickPreview('view');">
  <header class = "doc-header">
    <div class = "user-avatar">
      <vp-avatar [type] = "'article-avatar'" [user] = "{'email': document?.from }">
      </vp-avatar>
      <img class="app-logo-mini" src="assets/images/vncmail.svg" />
    </div>

    <div class="doc-details">
      <div class="doc-details__heading">
        <span class = "doc-title" *ngIf="documentTitle">{{ documentTitle }}</span>
        <span class = "doc-title" *ngIf="!documentTitle && authorName">{{ authorName }}</span>
        <span class = "doc-title non-capital" *ngIf="!documentTitle && !authorName">{{ this.document.from }}</span>
        <div class = "doc-star">
          <button *ngIf = "document.metadata && !document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();addStar(document)">
            <mat-icon class = "unstarred-icon">star_border</mat-icon>
          </button>
          <button *ngIf = "document.metadata && document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();removeStar(document)">
            <mat-icon class = "starred-icon">star</mat-icon>
          </button>
        </div>
      </div>
      <div class="user-info">
        <span *ngIf="documentTitle && authorName" class = "doc-title">{{ authorName }}</span>
        <span *ngIf="documentTitle && !authorName" class = "doc-title non-capital">{{ document.from }}</span>
        <span *ngIf="documentTitle" class="divider-dot">.</span>
        <div class = "doc-time">
          {{ document?.createdDt | vpDisplayTime : "MM/DD hh:mm" }}
        </div>
      </div>
    </div>


<!--    <img class="app-logo" src = "assets/images/vncmail.svg"/>-->
<!--    <span class = "doc-title">{{ document.title || document.from }}</span>-->
<!--    <div class = "doc-star">-->
<!--      <button *ngIf = "document.metadata && !document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();addStar(document)">-->
<!--        <mat-icon class = "unstarred-icon">star_border</mat-icon>-->
<!--      </button>-->
<!--      <button *ngIf = "document.metadata && document.metadata.isStarred" mat-icon-button (click) = "$event.stopPropagation();removeStar(document)">-->
<!--        <mat-icon class = "starred-icon">star</mat-icon>-->
<!--      </button>-->
<!--    </div>-->

<!--    <div class = "doc-attachment" (click) = "$event.stopPropagation();$event.preventDefault();"-->
<!--         [matMenuTriggerFor] = "mailAttachmentsMenu"-->
<!--         *ngIf = "document.mailAttachments && document.mailAttachments.length > 0">-->
<!--      <mat-icon (click) = "$event.stopPropagation();$event.preventDefault();"-->
<!--                [matMenuTriggerFor] = "mailAttachmentsMenu" class = "attach_file-icon">attach_file-->
<!--      </mat-icon>-->
<!--      <span class = "attachment-count"-->
<!--            [matMenuTriggerFor] = "mailAttachmentsMenu">{{document.mailAttachments.length}}</span>-->
<!--      <mat-icon (click) = "$event.stopPropagation();$event.preventDefault();"-->
<!--                [matMenuTriggerFor] = "mailAttachmentsMenu" class = "chevron-down-icon" fontSet = "mdi"-->
<!--                fontIcon = "mdi-chevron-down"></mat-icon>-->
<!--      <mat-menu #mailAttachmentsMenu = "matMenu" yPosition = "below" class = "attachment-items-menu">-->
<!--        <div *ngIf = "document.mailAttachments.length > 0">-->
<!--          <div mat-menu-item *ngFor = "let attachment of document.mailAttachments" (click) = "$event.stopPropagation();filePreview(attachment)">-->
<!--            <div class = "item-content">-->
<!--              <mat-icon *ngIf = "attachment.fileType =='image'">insert_photo</mat-icon>-->
<!--              <mat-icon *ngIf = "attachment.fileType == 'other'">description</mat-icon>-->
<!--              <mat-icon *ngIf = "attachment.fileType == 'pdf'" fontSet = "mdi" fontIcon = "mdi-file-pdf-box"></mat-icon>-->
<!--              <span class = "file-name">{{attachment.name}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div mat-menu-item class = "download-all-option" (click) = "$event.stopPropagation();downloadAllAttachments(document.mailAttachments)">-->
<!--            <div class = "item-content">-->
<!--              <mat-icon>get_app</mat-icon>-->
<!--              <span class = "download-all">{{'DOWNLOAD_ALL_ATTACHMENT_LABEL' | translate}}</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </mat-menu>-->
<!--    </div>-->

<!--    <div class="doc-info">-->
<!--      <button mat-icon-button (click)="$event.stopPropagation();underDevelopment()">-->
<!--        <mat-icon fontSet="mdi" fontIcon="mdi-information-variant"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->

    <div class = "doc-actions">
      <button mat-icon-button (click) = "$event.stopPropagation();openReplyMessage()" class = "reply-button">
        <mat-icon>reply</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();openReplyAllMessage()" mat-icon-button class = "reply-all-button">
        <mat-icon>reply_all</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();openForwardMessage()" mat-icon-button class = "forward-button">
        <mat-icon>forward</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();underDevelopment()" mat-icon-button class = "mark-as-read-button">
        <mat-icon>drafts</mat-icon>
      </button>
      <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action"
              [matMenuTriggerFor] = "mailOptionMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
    </div>
  </header>

  <div class = "doc-body" *ngIf = "document.shortContent">
    <div>
      <div *ngIf = "!showMore" class = "doc-content show-less"
           [innerHTML] = "renderContent(document.shortContent) | vpSafeHtml">
      </div>
      <div *ngIf = "showMore" class = "doc-content"
           [innerHTML] = "document.parsedContent | vpSafeHtml">
      </div>
      <a class = "show-more-button" href = "javascript:void(0)"
         *ngIf = "!showMore && ((document.parsedContent.length > limitedLength) || hasMoreLines())"
         (click) = "$event.stopPropagation(); getMessage(document)">{{ 'SHOW_MORE' | translate }}</a>
      <a class = "show-more-button" href = "javascript:void(0)"
         *ngIf = "showMore && ((document.parsedContent.length > limitedLength)  || hasMoreLines())"
         (click) = "$event.stopPropagation();showMore = false">{{ 'SHOW_LESS' | translate }}</a>
    </div>
    <div class = "quick-mail-loading" *ngIf = "isLoading">
      <vp-custom-spinner></vp-custom-spinner>
    </div>
  </div>

  <footer class = "doc-footer">
<!--    <div class = "doc-tags">-->
<!--      <span *ngFor="let tag of document.tags" class="tag">-->
<!--        {{tag.name}}-->
<!--      </span>-->
<!--      <button class = "add-tag-btn" mat-icon-button (click) = "underDevelopment()">-->
<!--        <mat-icon fontSet = "mdi" fontIcon = "mdi-tag-plus"></mat-icon>-->
<!--      </button>-->
<!--    </div>-->
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <div class="doc-footer__content">
        <div class = "doc-emoji">
          <!-- <mat-icon fontSet="mdi" fontIcon="mdi-emoticon-outline" (click)="$event.stopPropagation();underDevelopment()"></mat-icon> -->
          <!--          <mat-icon *ngIf = "document.metadata && document.metadata.isLiked"-->
          <!--                    class = "liked-icon"-->
          <!--                    fontSet="mdi" fontIcon="mdi-heart-outline"-->
          <!--                    (click)="$event.stopPropagation();underDevelopment()"-->
          <!--          ></mat-icon>-->
          <!--          <span *ngIf = "document.metadata && document.metadata.likeCount"> {{document.metadata.likeCount}}</span>-->
        </div>

<!--        <div class = "user-avatar">-->
<!--          <vp-avatar [type] = "'article-avatar'" [user] = "{'email': document?.from }">-->
<!--          </vp-avatar>-->
<!--        </div>-->
<!--        <span class = "doc-author">-->
<!--        {{ authorName }}-->
<!--      </span>-->
<!--        <div class = "doc-time">-->
<!--          {{ document?.createdDt | vpDisplayTime : "DD/MM/YYYY hh:mm" }}-->
<!--        </div>-->

<!--        <div class = "doc-emoji">-->
<!--          <mat-icon fontSet="mdi" (click)="$event.stopPropagation();underDevelopment()" fontIcon="mdi-emoticon-outline"></mat-icon>-->
<!--          &lt;!&ndash;        <div *ngIf="document?.likes">&ndash;&gt;-->
<!--          &lt;!&ndash;          <mat-icon fontSet="mdi" fontIcon="mdi-thumb-up"></mat-icon>&ndash;&gt;-->
<!--          &lt;!&ndash;          {{document.likes}}&ndash;&gt;-->
<!--          &lt;!&ndash;        </div>&ndash;&gt;-->
<!--        </div>-->

        <!-- <span class="divider-dot">.</span> -->

        <a class = "doc-reply" href = "javascript:void(0)" (click) = "$event.stopPropagation();showReplies = true;">{{ 'STICKY_NOTES' | translate }}</a>

        <span class="divider-dot">.</span>

        <div class = "doc-read">
          <div *ngIf = "document.unread" class = "mark-as-read"
                  (click) = "$event.stopPropagation();markAsRead()">
            <mat-icon fontSet = "mdi" fontIcon = "mdi-checkbox-marked-circle-outline"></mat-icon>
            <span class = "unread-text">{{ 'UNREAD' | translate }}</span>
            <span class = "mark-as-read">{{ 'MARK_AS_READ' | translate }}</span>
          </div>

          <div *ngIf = "!document.unread" class = "mark-as-unread"
                  (click) = "$event.stopPropagation();markAsUnRead()">
            <mat-icon fontSet = "mdi" fontIcon = "mdi-checkbox-marked-circle"></mat-icon>
            <span class = "unread-text">{{ 'READ' | translate }}</span>
            <span class = "mark-as-read">{{ 'MARK_AS_UNREAD' | translate }}</span>
          </div>
        </div>

        <span class="divider-dot">.</span>

        <button *ngIf="tags.length > 0"
                class = "tags-btn"
                mat-button
                vpCustomToolTip [contentTemplate]="template"
                (click) = "$event.stopPropagation();openUpdateTagsDialog()">
          <mat-icon fontSet = "mdi" fontIcon = "mdi-tag"></mat-icon>
          <span>{{tags.length}}</span>
          <ng-template #template>
            <vp-doc-tags-tooltip [tags]="tags"></vp-doc-tags-tooltip>
          </ng-template>
        </button>

        <button *ngIf="tags.length === 0" class = "add-tag-btn" mat-icon-button (click) = "$event.stopPropagation();openUpdateTagsDialog()">
          <mat-icon fontSet = "mdi" fontIcon = "mdi-tag-plus"></mat-icon>
        </button>

        <span class = "divider-dot" *ngIf = "document.mailAttachments && document.mailAttachments.length > 0">.</span>

        <div class = "doc-attachment" (click) = "$event.stopPropagation();$event.preventDefault();"
             [matMenuTriggerFor] = "mailAttachmentsMenu"
             *ngIf = "document.mailAttachments && document.mailAttachments.length > 0">
          <mat-icon
                     class = "attach_file-icon">attach_file
          </mat-icon>
          <span class = "attachment-count"
                >{{document.mailAttachments.length}}</span>
          <mat-icon
                    class = "chevron-down-icon" fontSet = "mdi"
                    fontIcon = "mdi-chevron-down"></mat-icon>
          <mat-menu #mailAttachmentsMenu = "matMenu" yPosition = "below" class = "attachment-items-menu">
            <div *ngIf = "document.mailAttachments.length > 0">
              <div mat-menu-item *ngFor = "let attachment of document.mailAttachments"
                   (click) = "$event.stopPropagation();filePreview(attachment)">
                <div class = "item-content">
                  <mat-icon *ngIf = "attachment.fileType =='image'">insert_photo</mat-icon>
                  <mat-icon *ngIf = "attachment.fileType == 'other'">description</mat-icon>
                  <mat-icon *ngIf = "attachment.fileType == 'pdf'" fontSet = "mdi"
                            fontIcon = "mdi-file-pdf-box"></mat-icon>
                  <span class = "file-name">{{attachment.name}}</span>
                </div>
              </div>
              <div mat-menu-item class = "download-all-option"
                   (click) = "$event.stopPropagation();downloadAllAttachments(document.mailAttachments)">
                <div class = "item-content">
                  <mat-icon>get_app</mat-icon>
                  <span class = "download-all">{{'DOWNLOAD_ALL_ATTACHMENT_LABEL' | translate}}</span>
                </div>
              </div>
            </div>
          </mat-menu>
        </div>

        <span class = "divider-dot">.</span>

        <div class = "expand-related-mails" (click) = "$event.stopPropagation();toggleShowRelatedMails()">
          <span *ngIf="!showRelatedMails">{{ 'EXPAND_RELATED_MAILS' | translate }}</span>
          <span *ngIf="showRelatedMails">{{ 'COLLAPSE_RELATED_MAILS' | translate }}</span>
        </div>
      </div>

      <div class="doc-reply-count" (click)="$event.stopPropagation();toggleShowReplies()">
        <span>
          {{commentsCount}}
        </span>
        <span>
         {{ (commentsCount === 1 ?  'REPLY' : 'REPLIES') | translate}}
        </span>
        <mat-icon *ngIf="showReplies" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
        <mat-icon *ngIf="!showReplies" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
      </div>
    </div>
  </footer>

  <footer class = "doc-footer--mobile">
    <button mat-icon-button (click) = "$event.stopPropagation();openReplyMessage()" class = "reply-button">
      <mat-icon>reply</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();openReplyAllMessage()" mat-icon-button class = "reply-all-button">
      <mat-icon>reply_all</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();openForwardMessage()" mat-icon-button class = "forward-button">
      <mat-icon>forward</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();underDevelopment()" mat-icon-button class = "mark-as-read-button">
      <mat-icon>drafts</mat-icon>
    </button>
    <button (click) = "$event.stopPropagation();" mat-icon-button class = "more-action"
            [matMenuTriggerFor] = "mailOptionMenu">
      <mat-icon>more_vert</mat-icon>
    </button>
  </footer>

  <footer class = "doc-footer--mobile">
    <div class="doc-reply-count" (click)="$event.stopPropagation();toggleShowReplies()">
      <mat-icon fontSet="mdi" fontIcon="mdi-comment-outline" class="comment-icon"></mat-icon>
      <span>{{ 'COMMENT' | translate }} {{commentsCount}}</span>
      <mat-icon *ngIf="showReplies" fontSet="mdi" fontIcon="mdi-chevron-down"></mat-icon>
      <mat-icon *ngIf="!showReplies" fontSet="mdi" fontIcon="mdi-chevron-up"></mat-icon>
    </div>

    <div class = "doc-emoji">
      <mat-icon fontSet="mdi" (click)="$event.stopPropagation();underDevelopment()" fontIcon="mdi-emoticon-outline"></mat-icon>
    </div>
  </footer>

  <mat-menu #mailOptionMenu = "matMenu">
    <button (click) = "$event.stopPropagation();showReplies = true;" mat-menu-item class = "comment-button">
      <mat-icon>comment</mat-icon>
      <span>{{ 'REPLY' | translate}}</span>
    </button>
  </mat-menu>
</article>

<vp-doc-replies [document]="document" [isCollapsed]="!showReplies" (commentsCount)="setCommentsCount($event)"></vp-doc-replies>

<div class="related-mails" *ngIf="showRelatedMails && relatedMails.length > 0">
  <vp-doc-mail-preview *ngFor="let mail of relatedMails" [document]="mail"></vp-doc-mail-preview>
</div>
