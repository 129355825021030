
/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { FormControl } from "@angular/forms";


@Component({
  selector: "vp-select",
  templateUrl: "./select.component.html"
})
export class VNCSelectComponent implements OnInit, AfterViewInit {

  @Input() size: "small" | "medium" | "large" = "large"; // Optional
  @Input() control: FormControl;

  @Input() name: string; // Optional
  @Input() required: boolean; // Optional
  @Input() placeholder: string; // Optional
  @Input() error: string; // Optional
  @Input() valid: boolean; // Optional
  @Input() label: string; // Optional
  @Input() helperText: string; // Optional
  @Input() leadingIcon: string; // Optional
  @Input() options: {value: any, viewValue: string}[] = [];

  open = false;
  @ViewChild("select") select: ElementRef;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.select.nativeElement.addEventListener("focus", () => {
     
      this.open = true;
    });

    this.select.nativeElement.addEventListener("blur", () => {
      
      this.open = false;
    });

  }

  public get classes(): string[] {
    const hasLeadingIcon = this.leadingIcon ? "has-leading-icon" : "";
    const hasError = this.error ? "error" : "";
    const isValid = this.valid ? "success" : "";
    return ["vnc-select__body", `${this.size}`, hasLeadingIcon, hasError, isValid];
  }

  public get labelClasses(): string[] {
    const classes = ["vnc-select__label"];
    if (!!this.leadingIcon) {
      classes.push("has-leading-icon");
    }

    if (this.open || !!this.control.value) {
      classes.push("selected");
    }
    return classes;
  }

}
