/*
 * VNCcommander - The brilliant centerpiece of VNClagoon with your activity stream and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UserProfile } from "../common/models/mail-models";
import { getContactById, getUserProfile } from "../reducers";
import { AuthService } from "../common/providers/auth.service";
import { Subject } from "rxjs";
import { CommonUtils } from "../common/utils/common-util";
import { environment } from "src/environments/environment.dev";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatDialog } from "@angular/material/dialog";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ConfigService } from "../config.service";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { Store } from "@ngrx/store";
import { AppState } from "../reducers/app";
import { ElectronService } from "../services/electron.service";
import { TranslateService } from "@ngx-translate/core";
import { filter, takeUntil, take } from "rxjs/operators";
// import { AppsDialogComponent } from "../shared/components/apps-dialog/apps-dialog.component";
import { Broadcaster } from "../common/providers/broadcaster.service";
// import { VersionDialogComponent } from "../shared/components/version-dialog/version-dialog.component";
import { AppRepository } from "../repositories/app.repository";
// import { GeneralSettingsDialogComponent } from "../shared/components/general-settings-dialog/general-settings-dialog.component";
// import { ProfileComponent } from "../profile/profile.component";
import { SetSidebarStatus } from "../actions/app";
import { FormControl } from "@angular/forms";
// import { MobileSettingsDialogComponent } from "src/app/shared/components/mobile-settings-dialog/mobile-settings-dialog.component";
// import { AdvSearchDialogComponent } from "../adv-search-dialog/adv-search-dialog.component";
// import { MobileTagsDialogComponent } from "../shared/components/mobile-tags-dialog/mobile-tags-dialog.component";
// import { MobileSearchesDialogComponent } from "../shared/components/mobile-searches-dialog/mobile-searches-dialog.component";
// import { MobileFiltersDialogComponent } from "../shared/mobile-filters-dialog/mobile-filters-dialog.component";

import { TopBarService, TopBarComponent, SearchBarService } from "vnc-library";

@Component({
  selector: "vp-header",
  templateUrl: "./header.component.html"
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  currentUser: UserProfile;
  searchKeyword: string;
  searchMode = false;
  private isAlive$ = new Subject<boolean>();
  showUserMenu: boolean = false;
  profileBackground = "url(" + CommonUtils.getFullUrl(environment.profileBackgroundImage) + ")";
  emailOptionExpanded: boolean = false;
  isSearchQueryExist: boolean = false;
  searchText: string = "";
  switchApp: boolean = false;
  currentUrl: string = "";
  isCordovaApp: boolean;
  isCordovaOrElectron: boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  penIcon = CommonUtils.getFullUrl("/assets/img/pen.svg");
  filterIcon = CommonUtils.getFullUrl("/assets/img/filter.svg");
  isMobileScreen: boolean = false;
  appLogo: string = "";
  appLogoDesktop = CommonUtils.getFullUrl(environment.appLogo);
  textLogo = environment.textLogo;
  brandName = environment.mobileTextLogo;
  private searchKeyDown = new Subject();
  avatarURL: string;
  searchFor = localStorage.getItem("getSearchFor") || "mail";
  includeSharedItems = (localStorage.getItem("includeSharedItems") || "true") === "true";
  isHandset: boolean;
  serviceDeskMenuItemName = "VNC service desk";
  userManual = "";
  dataPrivacy = "";
  termsOfUse = "";
  faqURL = "";
  serviceDeskURL: any;
  isSidebarExpanded: boolean;
  searchQueryControl: FormControl;
  searchSuggestions: string[];
  userName: string;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @ViewChild(TopBarComponent) topBarComponent: TopBarComponent;
  @ViewChild(TopBarService) topBarService: TopBarService;
  photoLastUpdate: any;
  defaultAvatar = CommonUtils.getFullUrl("/assets/profile-pic.png");
  bindedImgLoadErrorListner: any;
  userImageUrl: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private config: ConfigService,
    private changeDetectorRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private broadCaster: Broadcaster,
    private electronService: ElectronService,
    private appRepository: AppRepository,
    private translateService: TranslateService,
    private searchBarService: SearchBarService,
  ) {
   
    this.isCordovaApp = environment.isCordova;
    this.isCordovaOrElectron = environment.isCordova || environment.isElectron;
    this.currentUrl = this.router.routerState.snapshot.url;
    const queryParams = this.route.snapshot.queryParams;
    if (!!queryParams && !!queryParams.q) {
      this.searchQueryControl = new FormControl(queryParams.q);
    } else {
      this.searchQueryControl = new FormControl("");
    }

    this.appRepository.getSearchString().pipe(takeUntil(this.isAlive$)).subscribe(val => {
      const prevValue = this.searchQueryControl.value;
      if (prevValue !== val) {
        this.searchKeyword = "";
        this.searchQueryControl.setValue(val);
      }
    });

    this.changeDetectorRef.markForCheck();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.isHandset = this.breakpointObserver.isMatched([Breakpoints.Handset]);

    this.breakpointObserver
      .observe([Breakpoints.Handset])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isHandset = true;
        } else {
          this.isHandset = false;
        }
        this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
        this.changeDetectorRef.markForCheck();
      });
    this.appRepository.getLanguage().subscribe(lang => {
  
      this.setUrls(lang);
    });
  }

  setUrls(browserLang) {
    if (this.config.URLS.serviceDesk) {
      this.serviceDeskURL = this.config.URLS.serviceDesk;
    } else {
      this.serviceDeskURL = "https://redmine.vnc.biz/helpdesk/incidents/new";
    }
    if (browserLang === "en") {
      if (this.config.URLS.faq) {
        this.faqURL = this.config.URLS.faq;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq";
      }

      if (this.config.URLS.LegalNoticeTermOfUse) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse;
      } else {
        this.termsOfUse = "https://vnclagoon.com/terms";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/data-privacy-policy/";
      }
      if (this.config.URLS.userManual) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://en.docs.vnc.biz/vnccommander/usermanual/";
      }

    } else {
      if (this.config.URLS.faq_de) {
        this.faqURL = this.config.URLS.faq_de;
      } else {
        this.faqURL = "https://portal.vnc.biz/product-area/faq/vnccommander-faq-de";
      }
      if (this.config.URLS.LegalNoticeTermOfUse_de) {
        this.termsOfUse = this.config.URLS.LegalNoticeTermOfUse_de;
      } else {
        this.termsOfUse = "https://vnclagoon.com/de/Nutzungsbedingungen/";
      }

      if (this.config.URLS.LegalNoticeDataPrivacy_de) {
        this.dataPrivacy = this.config.URLS.LegalNoticeDataPrivacy_de;
      } else {
        this.dataPrivacy = "https://vnclagoon.com/de/datenschutzerklaerung/";
      }

      if (this.config.URLS.userManual_de) {
        this.userManual = this.config.URLS.userManual;
      } else {
        this.userManual = "https://de.docs.vnc.biz/vnccommander/usermanual/";
      }
    }
    this.changeDetectorRef.markForCheck();
  }

  ngAfterViewInit() {
     
    this.topBarService.isSearchBarActive$.subscribe(res => {
    });
    this.topBarService.onSelectItem$.subscribe(res => {
    });
    this.topBarService.searchControl.valueChanges.subscribe(res => {
    });
    this.topBarService.search$.subscribe(res => {
      this.setSearchQuery(res?.query);    
    });
  }

  ngOnInit() {
   

    this.store.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(profile => {
      this.currentUser = profile;
      this.changeDetectorRef.markForCheck();
      let avatarId = "";
      if (!!this.currentUser) {
        this.appRepository.getContactByEmail(this.currentUser.defaultMail).subscribe(res => {
          this.userName = res?.name || this.currentUser?.fullName || (this.currentUser?.firstName + " " + this.currentUser?.lastName);
        });
        if (environment.isElectron) {
          avatarId = this.electronService.md5(this.currentUser.defaultMail);
        } else {
          avatarId = md5(this.currentUser.defaultMail);
        }
        this.avatarURL = `${this.config.get("avatarURL")}/${avatarId}.jpg`;
        this.userImageUrl = this.avatarURL;
        const image: HTMLElement = document.createElement("img");
        image.setAttribute("src", this.avatarURL);
        this.bindedImgLoadErrorListner = this.imageFailedToLoad.bind(this);
        image.addEventListener("error", this.bindedImgLoadErrorListner);

      }
      this.changeDetectorRef.markForCheck();
    });

    this.router.events.pipe(takeUntil(this.isAlive$)).pipe(filter(e => e instanceof NavigationEnd)).subscribe(data => {
      this.currentUrl = this.router.routerState.snapshot.url;
      this.changeDetectorRef.markForCheck();
    });

    this.broadCaster.on<any>("closeSearch").pipe(takeUntil(this.isAlive$)).subscribe(apps => {
      this.clearSearchText();
    });

    this.searchQueryControl.valueChanges.subscribe(
      query => {
        if (!!query) {
          this.getSuggestions(query);
        }
      }
    );
  }

  imageFailedToLoad(event: any) {
    console.log("[HeaderComponent][imageFailedToLoad] failed to load", event);
    this.userImageUrl = "";
    this.changeDetectorRef.markForCheck();
  }

  private avatarVersion() {
    let version;
    const currentTimestamp = new Date().getTime();
    // // load new ver of avatar
    if ((!this.photoLastUpdate
      || (this.photoLastUpdate > 0 && currentTimestamp - this.photoLastUpdate >= this.config.AVATAR_SYNC_INTERVAL)
      || (this.photoLastUpdate < 0 && currentTimestamp - Math.abs(this.photoLastUpdate) >= this.config.AVATAR_SYNC_INTERVAL)
    )
    ) {
      // set new version
      version = "?ver=" + currentTimestamp;
      // use old ver
    } else {
      if (this.photoLastUpdate && this.photoLastUpdate > 0) {
        version = "?ver=" + this.photoLastUpdate;
      } else {
        version = null; // no version available yet, so show a default image
      }
      
    }
    return version;
  }

  getSingleEmail(emails) {
    if (Array.isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  openMenu(): void {
    this.trigger.openMenu();
  }

  toggleMobileSearch(): void {
    this.searchMode = !this.searchMode;
    this.clearSearchText();
  }

  searchOnKeyUp() {
    this.searchKeyDown.next(true);
  }

  clearSearchText(): void {
    this.searchKeyword = "";
    this.searchQueryControl.setValue("");
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
  }

  switchAppMenuOption() {
    if (this.isMobileScreen) {
      this.showUserMenu = true;
    } else {
      this.switchApp = this.switchApp ? false : true;
    }
    this.changeDetectorRef.markForCheck();
  }

  closeAppSwitcher() {
    this.switchApp = false;
    this.changeDetectorRef.markForCheck();
  }

  // selectedOption(event: MatAutocompleteSelectedEvent): void {
  //   this.searchKeyword = event.option.value as string;
  // }

  logout(): void {
    this.showUserMenu = false;
    this.auth.logoutWebAndApp();
    this.changeDetectorRef.markForCheck();
  }

  navigatorTo(url: string): void {
    this.currentUrl = this.router.routerState.snapshot.url;
    this.router.navigate([url]);
  }

  async openAppSwitcherDialog() {
    const { AppsDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/components/apps-dialog/apps-dialog.component"
      );
    this.matDialog.open(AppsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async versionDialog() {
    const { VersionDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/components/version-dialog/version-dialog.component"
      );
    this.matDialog.open(VersionDialogComponent, {
      maxWidth: "100%",
      width: "400px",
      height: "561px",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async generalSettings() {
    const { GeneralSettingsDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/general-settings-dialog/general-settings-dialog.component"
      );
    this.matDialog.open(GeneralSettingsDialogComponent, {
      width: "400px",
      height: "290px",
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async profile() {
    const { ProfileComponent } = await import(
      /* webpackPrefetch: true */
      "../profile/profile.component"
    );
    this.appRepository.getLoggedInUserInfo().pipe(take(1)).subscribe(data => {
      this.showUserMenu = false;
      this.changeDetectorRef.markForCheck();
      let contact = data;
     
      const dialogRef = this.matDialog.open(ProfileComponent, {
        autoFocus: false,
        panelClass: "user_contact_detail_dialog",
        data: { contact: contact },
      });
      dialogRef.afterClosed().subscribe((res: any) => {
       
      });
    });
  }

  // search() {
  //   if (!this.searchKeyword) {
  //     this.appRepository.setSearchString("");
  //     this.router.navigate(["/"]);
  //   } else {
  //     this.router.navigate(["/search"], { queryParams: { q: this.searchKeyword } });
  //   }
  // }

  selectQueryOnEnter() {
    this.setSearchQuery(this.searchQueryControl.value);
    this.autocomplete.closePanel();
  }

  selectQuery(event: any) {
    this.setSearchQuery(event);
  }

  setSearchQuery(query: string) {
    if (!query) {
      this.appRepository.setSearchString("");
      this.router.navigate(["/"]);
    } else {
      this.router.navigate(["/search"], { queryParams: { q: query } });
    }
  }

  getSuggestions(query: string) {
    this.appRepository.getSuggestions(query).subscribe(
      res => {
        
        this.searchSuggestions = res.map(v => v.term);
        this.changeDetectorRef.markForCheck();
      }
    );
  }

  underDevelopment() {
    this.appRepository.underDevelopment();
  }

  toggleRightSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
    this.store.dispatch(new SetSidebarStatus(this.isSidebarExpanded));
    this.changeDetectorRef.markForCheck();
  }

  async openAdvancedSearch() {
    const { AdvSearchDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../adv-search-dialog/adv-search-dialog.component"
      );
    this.matDialog.open(AdvSearchDialogComponent, {
      width: "100vw",
      minWidth: "100vw",
      height: "100vh",
      autoFocus: true,
      data: { keyword: this.searchQueryControl.value },
      panelClass: "commander__full_dialog"
    });
  }

  async openMobileSettingsDialog() {
    const { MobileSettingsDialogComponent } = await import(
      /* webpackPrefetch: true */
      "src/app/shared/components/mobile-settings-dialog/mobile-settings-dialog.component"
      );
    this.matDialog.open(MobileSettingsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async openMobileTagsDialog() {
    const { MobileTagsDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/components/mobile-tags-dialog/mobile-tags-dialog.component"
      );
    this.matDialog.open(MobileTagsDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async openMobileSearchesDialog() {
    const { MobileSearchesDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/components/mobile-searches-dialog/mobile-searches-dialog.component"
      );
    this.matDialog.open(MobileSearchesDialogComponent, {
      maxWidth: "100%",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }

  async openMobileFiltersDialog() {
    const { MobileFiltersDialogComponent } = await import(
      /* webpackPrefetch: true */
      "../shared/mobile-filters-dialog/mobile-filters-dialog.component"
      );
    this.matDialog.open(MobileFiltersDialogComponent, {
      maxWidth: "80vw",
      maxHeight: "80vh",
      autoFocus: false,
      panelClass: "commander__dialog"
    });
  }
  searchGlobal() {
  }
  toggleSidebar() {

  }
  openNotificationDialog() {

  }
  comingSoon() {
    this.appRepository.comingSoon();
  }
}
